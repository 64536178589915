import {
  ExpandMore,
  LooksTwoOutlined,
  Download,
  LooksOneOutlined,
  Looks4Outlined,
  Looks5Outlined,
  Looks3Outlined,
  Looks6Outlined,
  HelpCenterOutlined,
  DownloadOutlined,
  CompareArrowsOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withFirebase } from "../Firebase";

const R500UpdateSteps = (props) => {
  const { t } = useTranslation();
  const { firebase, serialNumber, softwareVersion, sd, handleNext } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isnewTagDownloaded, setIsnewTagDownloaded] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileNameAlertOpen, setFileNameAlertOpen] = useState("");

  const downloadFile = () => {
    setIsDownloading(true);
    firebase
      .getSoftwareUpdateFile(serialNumber, softwareVersion)
      .then((name) => {
        setFileNameAlertOpen(t("fileNameAlert", { fileName: name }));
        setIsDownloading(false);
        setIsnewTagDownloaded(true);
        sd(true);
      })
      .catch(() => {
        fileNameAlertOpen("");
        setIsDownloading(false);
        setModalText("Vuelva a intentarlo, si el error persiste pongase en contacto con support@bexencardio.com");
        setModalTitle("Error en la descarga");
        setIsModalOpen(true);
        sd(false);
      });
  };

  const closeFileNameAlert = () => {
    setFileNameAlertOpen("");
  };

  const resetModal = () => {
    setModalText("");
    setModalTitle("");
    isModalOpen(false);
  };

  const m = serialNumber.charAt(3);

  return (
    <Container>
      {!isnewTagDownloaded ? (
        <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} style={{ minHeight: "67vh" }}>
          <Grid item xs={12} lg={4} container justifyContent={"center"}>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color={"primary"}>
                    {t("softwareDownload")}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={isDownloading}
                    fullWidth
                    onClick={downloadFile}
                    style={{ height: 190, borderRadius: 9 }}
                  >
                    {isDownloading ? (
                      <Box sx={{ width: "100%" }} style={{ marginTop: 16 }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <Download style={{ fontSize: 64 }} />
                    )}
                  </Button>
                </Grid>

                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography style={{ textAlign: "center" }} variant="body1">
                    {t("softwareDownloadButton")}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500], paddingTop: 16 }}>
              {t("videoDemoTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent={"center"}>
            <Grid item xs={12} lg={12} container>
              <Card style={{ padding: 16, borderRadius: 16 }}>
                <Grid item xs={12} style={{ marginTop: 9 }}>
                  <Typography>{t("updateVideoText", { model: m == "5" ? "Reanibex 500" : "Reanibex 300" })}</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Grid item xs={12} md={8} lg={7} xl={6}>
                    <video width="100%" controls style={{ borderRadius: 16, marginTop: 16 }}>
                      <source
                        src={
                          m == "5"
                            ? "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR500-Update.mp4?alt=media&token=b84f2f53-2982-4a0b-9808-1002e8bcf368"
                            : "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR300_Update.mp4?alt=media&token=816c6994-a4b4-46d1-b1bd-ea3f1a4109a9"
                        }
                        type="video/mp4"
                      />
                    </video>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Button onClick={handleNext} variant={"contained"} color="success" style={{ marginTop: 16 }}>
                {t("next")}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <div
                style={{
                  border: "2px solid #9c27b0",
                  width: 45,
                  height: 45,
                  lineHeight: 45,
                  textAlign: "center",
                  zIndex: 99,
                  borderRadius: "50%",
                  alignContent: "center",
                }}
              >
                <CompareArrowsOutlined
                  color="secondary"
                  style={{
                    zIndex: 100,
                    position: "absolute",
                    transform: "rotate(90deg)",
                    height: 38,
                    width: 38,
                    marginTop: 4,
                    marginLeft: -19,
                  }}
                />
              </div>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
              {t("stepbyStep")}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} container>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <LooksOneOutlined ontSize="large" />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <img
                    src={require(m == "5" ? "../assets/img/R500-menu.jpg" : "../assets/img/R300-Menu.jpg")}
                    style={{ width: "85%", marginTop: 16, borderRadius: 9 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography variant="body1">{t("copyFileOnUSB")}</Typography>
                  <Typography variant="body1">{t("EnterConfMode")}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} container>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <LooksTwoOutlined ontSize="large" />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <img
                    src={require(m == "5" ? "../assets/img/R500-updateMenu.jpg" : "../assets/img/R300-update.jpg")}
                    style={{ width: "85%", marginTop: 16, borderRadius: 9 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography style={{ textAlign: "center" }} variant="body1">
                    {t(m == "5" ? "selectUpdateText500" : "selectUpdateText300")}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} container>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Looks3Outlined ontSize="large" />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <img
                    src={require(m == "5" ? "../assets/img/R500-UpdateIcon.jpg" : "../assets/img/R300-UpdateIcon.jpg")}
                    style={{ width: "85%", marginTop: 16, borderRadius: 6 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography style={{ textAlign: "center" }} variant="body1">
                    {t("updateStateStep")}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} container>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Looks4Outlined ontSize="large" />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <img
                    src={require(m == "5" ? "../assets/img/R500-AutoTest.jpg" : "../assets/img/R300-AutoTest.jpg")}
                    style={{ width: "85%", marginTop: 16, borderRadius: 6 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography style={{ textAlign: "center" }} variant="body1">
                    {t("updateTest")}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} container>
            <Card style={{ padding: 16 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <HelpCenterOutlined ontSize="large" />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <img
                    src={require(m == "5" ? "../assets/img/R500-Languaje.jpg" : "../assets/img/R300-Languaje.jpg")}
                    style={{ width: "85%", marginTop: 16, borderRadius: 6 }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography style={{ textAlign: "center" }} variant="body1">
                    {t("languajeChange")}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      <Snackbar open={fileNameAlertOpen != ""} onClose={closeFileNameAlert} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={closeFileNameAlert} severity="warning" sx={{ width: "100%" }}>
          {fileNameAlertOpen}
        </Alert>
      </Snackbar>
      <Dialog open={isModalOpen}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>{modalText}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              resetModal();
            }}
          >
            {t("acept")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default withFirebase(R500UpdateSteps);
