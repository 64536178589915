import React from "react";
import { withRouter } from "react-router-dom";

import AuthUserContext from "./context";
import { withFirebase } from "../../Firebase";
import * as ROUTES from "../../constants/Routes";
import { useNavigate } from "react-router-dom";
import PermissionDenied from "../../pages/PermissionDenied";
import HomeRedirect from "../HomeRedirect";
import VerificationPage from "../../pages/VerificationPage";
import Verification from "../Verification";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (!condition(authUser)) {
          //this.navigate(ROUTES.SIGN_IN);
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            if (!!authUser) {
              if (authUser.emailVerified) {
                return condition(authUser) ? <Component {...this.props} /> : <PermissionDenied />;
              } else {
                return <Verification />;
              }
            } else {
              return <HomeRedirect />;
            }
          }}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
