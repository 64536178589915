import { Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import RegisterForm from "../components/RegisterForm";

const LandingPage_V2 = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems={"center"}
      style={{
        flex: 1,
        minHeight: "100vh",
        paddingBottom: 16,
        paddingTop: 16,
        alignItems: "center",
        background: "linear-gradient(-45deg,#ab47bc, #9198e5)",
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid container item xs={12} md={6} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h3" color={"white"} style={{ fontWeight: 700 }}>
                {t("pageTitle")}
              </Typography>

              <Typography variant="body1" color={"lightgray"} style={{ fontWeight: 500, fontSize: "1rem", marginTop: "5vh" }}>
                {t("pageSubtitle")}
              </Typography>
              <Typography variant="body2" color={"whitesmoke"} style={{ fontWeight: 800, fontSize: "1rem" }}>
                (*) {t("supportText")} support@bexencardio.com
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
            <Grid item xs={12} md={10} lg={9}>
              <Card style={{ padding: 32, borderRadius: 8 }}>
                <Grid item xs={12}>
                  <RegisterForm />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LandingPage_V2;
