import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Autocomplete,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import { useTranslation } from "react-i18next";
import { blueGrey, green, lightBlue, purple } from "@mui/material/colors";
import Confetti from "react-confetti";
import { PhotoCamera, WarningRounded } from "@mui/icons-material";
import R500UpdateSteps from "../components/R500UpdateSteps";
import { withAuthorization } from "../components/Session";

const UpdateProccess = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const { firebase } = props;
  const steps = [t("serialNumber"), t("softwareVersion"), t("update"), t("verify")];
  const SoftVregx = "^(V_)?[0-9]{1,1}_[0-9]{2,2}(_[A-Z]){1,1}$";

  const [activeStep, setActiveStep] = useState(0);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [loadingStep, setLoadingStep] = useState(false);
  const [updatedVersion, setUpdatedVersion] = useState("");
  const [isSoftwareDownloaded, setIsSoftwareDownloaded] = useState(false);
  const [updateCheckImage, setupdateCheckImage] = useState("");
  const [manualUpdate, setManualUpdate] = useState(false);
  const [manualValidation, setManualValidation] = useState(false);

  useEffect(() => {
    if (!!params.serialNumber && !!params.softwareVersion) {
      setSerialNumber(params.serialNumber);
      setSoftwareVersion(params.softwareVersion);
      setActiveStep(1);
    }
  }, []);

  const handleNext = () => {
    if (activeStep == 0) {
      setLoadingStep(true);
      if (serialNumber == "") {
        setSnackBarText(t("serialcantBeEmpty"));
        setSnackBarOpen(true);
        setLoadingStep(false);
      } else {
        firebase
          .checkSerial(serialNumber)
          .then(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setLoadingStep(false);
          })
          .catch((err) => {
            !err.isNull && setSnackBarText(t(err.code));
            !err.isNull && setSnackBarOpen(true);
            setManualUpdate(err.isNull);
            setLoadingStep(false);
            setSerialNumber("");
          });
      }
    } else if (activeStep == 1) {
      setLoadingStep(true);
      firebase
        .checkSorftVer(serialNumber, softwareVersion)
        .then((v) => {
          console.log(v);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setLoadingStep(false);
        })
        .catch((err) => {
          setSnackBarText(t(err.code));
          setSnackBarOpen(true);
          setLoadingStep(false);
          setSoftwareVersion("");
        });
    } else if (activeStep == 3) {
      setLoadingStep(true);

      firebase
        .checkUpdatedVersion(softwareVersion, updatedVersion, serialNumber, updateCheckImage, manualValidation)
        .then((v) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setLoadingStep(false);
        })
        .catch((err) => {
          setSnackBarText(t(err.code));
          setSnackBarOpen(true);
          setLoadingStep(false);
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep == 0) {
      navigate(ROUTES.HOME);
    } else if (activeStep == 2) {
      setIsSoftwareDownloaded(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const snackBarOpenHandleClose = () => {
    setSnackBarOpen(false);
  };

  const checkNextActive = () => {
    let isValid = false;
    //console.log(activeStep, updatedVersion);
    switch (activeStep) {
      case 0:
        isValid = serialNumber !== "";
        break;

      case 1:
        isValid = new RegExp(SoftVregx).test(softwareVersion);
        break;
      case 2:
        isValid = isSoftwareDownloaded;
        break;
      case 3:
        isValid = new RegExp(SoftVregx).test(updatedVersion) && manualValidation;
        break;
    }
    return isValid;
  };

  let activeNext = checkNextActive();

  return (
    <Grid container style={{ minHeight: "100vh", paddingTop: "15vh" }}>
      <Container>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            labelProps.optional =
              index == 0 ? (
                <Typography variant="caption">{serialNumber}</Typography>
              ) : (
                index == 1 && <Typography variant="caption">{softwareVersion}</Typography>
              );

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <ProccesEnd />
            <Container>
              <Grid container spacing={2} alignItems={"center"} style={{ minHeight: "70vh" }}>
                <Grid item xs={12} lg={5}>
                  <img
                    src={require(serialNumber.charAt(3) === "5" ? "../assets/img/R500-Vertical.png" : "../assets/img/Reanibex-300-DEA.png")}
                    style={{ marginTop: 16, width: "80%" }}
                  />
                </Grid>
                <Grid item xs={12} lg={7} container alignItems={"center"}>
                  <Typography variant="h3">
                    {serialNumber.charAt(3) === "5"
                      ? t("deviceSuccessUpdate", { model: "500" })
                      : t("deviceSuccessUpdate", { model: "300" })}
                  </Typography>
                </Grid>
              </Grid>
            </Container>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button variant="contained" onClick={() => navigate(ROUTES.HOME)}>
                {t("home")}
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep == 0 && (
              <SerianNumberStep
                updateSerial={setSerialNumber}
                SN={serialNumber}
                manual={manualUpdate}
                setManual={setManualUpdate}
                firebase={firebase}
              />
            )}
            {activeStep == 1 && (
              <SoftwareVersionStep
                updateVersion={setSoftwareVersion}
                SV={softwareVersion}
                serialNumber={serialNumber}
                firebase={firebase}
              />
            )}
            {activeStep == 2 && (
              <R500UpdateSteps
                serialNumber={serialNumber}
                softwareVersion={softwareVersion}
                sd={setIsSoftwareDownloaded}
                handleNext={handleNext}
              />
            )}
            {activeStep == 3 && (
              <ValidateUpdate
                updateVersion={setUpdatedVersion}
                setupdateCheckImage={setupdateCheckImage}
                setManualValidation={setManualValidation}
              />
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }} style={{ marginBottom: 16 }}>
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                {t("back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                onClick={handleNext}
                disabled={!activeNext || loadingStep}
                variant={!activeNext ? "text" : "contained"}
                color="success"
              >
                {activeStep === steps.length - 1 ? t("finish") : t("next")}
                {loadingStep && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Container>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={snackBarOpenHandleClose}
        severity="error"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">{snackBarText}</Alert>
      </Snackbar>
    </Grid>
  );
};
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(UpdateProccess);

const SerianNumberStep = ({ updateSerial, SN, manual, setManual, firebase }) => {
  const { t } = useTranslation();
  const [iserror, setIserror] = useState(false);
  const [helperText, sethelperText] = useState("");
  const [serialNumber, setSerialNumber] = useState(SN);
  const [openingText, setOpeningText] = useState("");
  const [oldSerial, setOldSerial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alreadyUpdated, setAlreadyUpdated] = useState(false);
  const [notAffected, setNotAffected] = useState(false);
  const [isReturnable, setIsReturnable] = useState(false);

  const checkSN = () => {
    let sn = serialNumber;
    sn = sn.charAt(4) === "/" ? sn.split("/")[1] : sn;
    const regx500 = "^2005[0-9]{4,4}$";
    const regx500Old = "^9004[0-9]{4,4}$";
    const regx300 = "^2004[0-9]{4,4}$";

    //console.log(sn, new RegExp(regx500).test(serialNumber), new RegExp(regx300).test(serialNumber));

    if (serialNumber == "") {
      setIserror(false);
      sethelperText("");
      setOldSerial(false);
    } else {
      if (!new RegExp(regx500).test(sn) && !new RegExp(regx300).test(sn) && !new RegExp(regx500Old).test(sn)) {
        setIserror(true);
        sethelperText(t("device/invalidSerial"));
        updateSerial("");
      } else if (new RegExp(regx500Old).test(sn) || ["20050507", "20040535"].includes(sn)) {
        setOldSerial(true);
      } else {
        setIserror(false);
        sethelperText("");
        setOldSerial(false);
      }
    }
  };

  const checkRecallableSN = (e) => {
    e.preventDefault();
    setIsLoading(true);
    firebase
      .checkSerial(serialNumber)
      .then((updated) => {
        updateSerial(serialNumber);
        setIsLoading(false);
        setOpenSnack(true);
        setAlreadyUpdated(updated);
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "devices/invalidSerial" && !err.isNull) {
          setNotAffected(true);
        } else if (!err.isNull) {
          sethelperText(err.code);
          setIserror(true);
        } else {
          setIsReturnable(true);
        }
        updateSerial("");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    manual && setOldSerial(true);
    setManual(false);
  }, [manual]);

  const openTab = () => {
    const opText = t("openingNewTab");
    let aux = ".";
    const intervalID = setInterval(() => {
      aux = aux + ".";
      setOpeningText(opText + aux);
    }, 500);
    setTimeout(() => {
      clearInterval(intervalID);
      window.open(ROUTES.SERIALNUMBER, "_blank");
      setOpeningText("");
    }, 2000);
  };

  const onChange = (e) => {
    setSerialNumber(e.target.value.trim().slice(0, 8));
  };

  useEffect(() => {
    serialNumber.length == 8 && checkSN();
    if (serialNumber.length !== 8) {
      updateSerial("");
      sethelperText("");
      setIserror(false);
      setOldSerial(false);
    }
  }, [serialNumber]);

  return (
    <form onSubmit={checkRecallableSN}>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={5} style={{ paddingTop: 36, minHeight: "70vh" }}>
        <Grid item xs={12} md={10} lg={6}>
          <Card style={{ padding: 16, borderRadius: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} container alignItems={"center"} style={{ marginBottom: 9 }}>
                <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
                  {t("SNStepTitle")}
                </Typography>
                <Button variant="outlined" size="small" color="info" style={{ marginLeft: 16 }} onClick={openTab}>
                  {t("seeInstructions")}
                </Button>
                <Typography>{openingText}</Typography>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} md={8} container alignItems={"center"}>
                  <TextField
                    id="outlined-basic"
                    label={t("insertSerial")}
                    variant="outlined"
                    fullWidth
                    type={"number"}
                    error={iserror}
                    onChange={onChange}
                    value={serialNumber}
                    onBlur={checkSN}
                  />
                </Grid>
                <Grid item xs={12} md={4} container alignItems={"center"}>
                  <Button
                    variant="outlined"
                    fullWidth
                    style={{ height: 56, textTransform: "none" }}
                    disabled={serialNumber === "" || iserror}
                    onClick={checkRecallableSN}
                  >
                    {t("check")}
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid>
                  <FormHelperText error style={{ paddingLeft: 8 }}>
                    {t(helperText)}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Dialog open={isReturnable}>
          <DialogTitle>{t("contactSupport")}</DialogTitle>
          <DialogContent>{t("toUpdateContact")}</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsReturnable(false)}>{t("close")}</Button>{" "}
          </DialogActions>
        </Dialog>

        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity={"warning"}>{alreadyUpdated ? t("deviceAlreayUpdated") : t("updateAvailablePressNext")}</Alert>
        </Snackbar>
        <ProccessDialog mainOpen={oldSerial} title={t("contactSupport")} content={t("toUpdateContact")} />
      </Grid>
      <Snackbar open={notAffected} onClose={() => setNotAffected(false)} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={() => setNotAffected(false)} severity="success" sx={{ width: "100%" }}>
          <Typography> {t("devices/invalidSerial")}</Typography>
        </Alert>
      </Snackbar>
    </form>
  );
};

const SoftwareVersionStep = ({ SV, updateVersion, serialNumber, firebase }) => {
  const { t } = useTranslation();
  const regx = "^(V_)?[0-9]{1,1}_[0-9]{2,2}(_[A-Z]){1,1}$";

  const [openingText, setOpeningText] = useState("");
  const [version, setVersion] = useState(SV);
  const [helperText, setHelperText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [deviceYear, setDeviceYear] = useState("");
  const [showBatteryModal, setShowBatteryModal] = useState(false);
  const [GSM, setGSM] = useState(false);

  const openTab = () => {
    const opText = t("openingNewTab");
    let aux = ".";
    const intervalID = setInterval(() => {
      aux = aux + ".";
      setOpeningText(opText + aux);
    }, 500);
    setTimeout(() => {
      clearInterval(intervalID);
      let model = serialNumber.substring(0, 4) == "2004" ? "R300" : "R500";
      window.open(ROUTES.SOFWAREVERSION.replace(":model", model), "_blank");
      setOpeningText("");
    }, 2000);
  };

  const handleChange = (e) => {
    const v = e.target.value.toUpperCase();
    if (e.target.value.length <= 8) {
      setVersion(v);
    } else {
      updateVersion("");
    }
  };

  const checkVersion = (e) => {
    e.preventDefault();
    setLoading(true);
    let v = version;
    const r = "(_[A-Z]){1,1}$";
    const incompleteRegex = "^[0-9]{1,1}_[0-9]{2,2}(_[A-Z]){1,1}$";

    if (v.length == 6 && new RegExp(incompleteRegex).test(v)) {
      v = "V_" + v;
    }

    if (!new RegExp(r).test(v)) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    //setVersion(v);
    if (!new RegExp(regx).test(v)) {
      //console.log(v);
      updateVersion("");
      setHelperText(t("invalidTagFormat"));
      setLoading(false);
    } else {
      firebase
        .checkSorftVer(serialNumber, v)
        .then((device) => {
          updateVersion(v);
          setHelperText("");
          setLoading(false);
          setShowBatteryModal(device.year < 2019);
          setOpenSnack(true);
          setGSM(device.GSM);
        })
        .catch((err) => {
          setHelperText(t(err.code));
          setLoading(false);
          updateVersion("");
        });
    }
  };

  useEffect(() => {
    //version.length == 8 && checkVersion();
    //version.length == 6 && checkVersion();

    if (version.length !== 8) {
      updateVersion("");
      setHelperText("");
      setOpen(false);
    }
  }, [version]);

  return (
    <form onSubmit={checkVersion}>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={5} style={{ paddingTop: 36, minHeight: "70vh" }}>
        <Grid item xs={12} md={10} lg={6}>
          <Card style={{ padding: 16, borderRadius: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} container alignItems={"center"} style={{ marginBottom: 9 }}>
                <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
                  {t("SoftwareVersiontitle")}
                </Typography>
                <Button variant="outlined" size="small" color="info" style={{ marginLeft: 16 }} onClick={openTab}>
                  {t("seeInstructions")}
                </Button>
                <Typography>{openingText}</Typography>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} md={8} container alignItems={"center"}>
                  <TextField
                    label={t("insertTag")}
                    variant="outlined"
                    fullWidth
                    placeholder="V_8_03_A"
                    value={version}
                    error={helperText != ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    disabled={helperText !== "" || version == ""}
                    fullWidth
                    variant="outlined"
                    onClick={checkVersion}
                    style={{ height: 56, textTransform: "none" }}
                    type="submit"
                  >
                    {t("check")}
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid>
                  <FormHelperText error style={{ paddingLeft: 8 }}>
                    {t(helperText)}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Snackbar
          open={!showBatteryModal && openSnack}
          onClose={() => setOpenSnack(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={GSM ? "warning" : "success"}>
            {GSM && t("GSMMessage")}
            {t("serialandTagCanUpdate")}
          </Alert>
        </Snackbar>
        <Dialog open={showBatteryModal && openSnack}>
          <DialogTitle>
            <Grid container alignItems={"center"}>
              <WarningRounded color="warning" style={{ marginRight: 9 }} />
              {t("needUpdate")}
            </Grid>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 0 }}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3} lg={3} container alignItems={"center"} justifyContent={"center"}>
                <img src={require("../assets/img/Bateria_Fecha.jpg")} style={{ borderRadius: 16, width: "100%" }} />
              </Grid>
              <Grid item xs={9} lg={9} container>
                <Typography style={{ fontSize: "1.1rem", fontWeight: 400 }} color={"GrayText"}>
                  {t("checkBatteryDate")}
                </Typography>
                <Typography style={{ fontSize: "1.1rem", fontWeight: 400 }} color={"GrayText"}>
                  {t("ifDatePrev2019")}
                </Typography>
              </Grid>
              {GSM && (
                <Grid item xs={12}>
                  {t("GSMMessage")}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowBatteryModal(false)} style={{ textTransform: "none" }}>
              {t("tesIAgree")}
            </Button>
          </DialogActions>
        </Dialog>

        <ProccessDialog mainOpen={open} title={t("tagIncomplete")} content={t("tagIncompleteText")} />
      </Grid>
    </form>
  );
};

const ValidateUpdate = ({ updateVersion, setupdateCheckImage, setManualValidation }) => {
  const { t } = useTranslation();
  const regx = "^(V_)?[0-9]{1,1}_[0-9]{2,2}(_[A-Z]){0,1}$";

  const [deviceImage, setDeviceImage] = useState("");
  const [openingText, setOpeningText] = useState("");
  const [version, setVersion] = useState("");
  const [helperText, setHelperText] = useState("");
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const openTab = () => {
    const opText = t("openingNewTab");
    let aux = ".";
    const intervalID = setInterval(() => {
      aux = aux + ".";
      setOpeningText(opText + aux);
    }, 500);
    setTimeout(() => {
      clearInterval(intervalID);
      window.open(ROUTES.SOFWAREVERSION, "_blank");
      setOpeningText("");
    }, 2000);
  };

  const handleChange = (e) => {
    const v = e.target.value.toUpperCase();
    setHelperText("");
    if (e.target.value.length <= 8) {
      setVersion(v);
      new RegExp(regx).test(v) && updateVersion(v);
    } else {
      updateVersion("");
    }
  };

  const checkVersion = () => {
    let v = version;
    const r = "(_[A-Z]){1,1}$";
    console.log(v);
    if (!new RegExp(r).test(v)) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (v.charAt(0) != "V") {
      v = "V_" + v;
    }
    setVersion(v);
    if (!new RegExp(regx).test(v)) {
      console.log(v);
      setHelperText(t("invalidTag"));
      updateVersion("");
    } else {
      updateVersion(v);
      setHelperText("");
    }
  };

  const validateCheck = () => {
    setChecked(!checked);
    setManualValidation(!checked);
  };

  return (
    <Grid container>
      <Grid item xs={12} container alignItems={"center"} style={{ minHeight: "65vh", marginTop: 32 }}>
        <Card style={{ padding: 16, borderRadius: 16 }}>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} container alignItems={"center"} style={{ marginBottom: 9 }}>
                <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
                  {t("SoftwareVersiontitle")}
                </Typography>
                <Button variant="outlined" size="small" color="info" style={{ marginLeft: 16 }} onClick={openTab}>
                  {t("seeInstructions")}
                </Button>
                <Typography>{openingText}</Typography>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} container alignItems={"center"}>
                  <TextField
                    label={t("insertNewTag")}
                    variant="outlined"
                    fullWidth
                    placeholder="V_8_04_A"
                    value={version}
                    helperText={helperText}
                    error={helperText != ""}
                    onChange={handleChange}
                    onBlur={checkVersion}
                    style={{ marginTop: 14 }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid item xs={12} style={{ marginBottom: 9 }}>
                <Tooltip title={<Typography>{t("optionalImage")}</Typography>}>
                  <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
                    {t("confirmImageTitle")}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={{ height: 56, width: "100%", textTransform: "none", marginTop: 15 }}
                  component="label"
                  startIcon={<PhotoCamera />}
                  color="primary"
                >
                  {t("imageButton")}
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                    onChange={(e) => {
                      try {
                        var reader = new FileReader();
                        var url = reader.readAsDataURL(e.target.files[0]);
                        //console.log(e.target.files[0]);
                        if (e.target.files[0].size > 10000000) {
                          setDeviceImage("");
                          setupdateCheckImage("");
                          setSnackOpen(true);
                        } else {
                          reader.onloadend = function (e) {
                            //console.log(reader.result);
                            setDeviceImage(reader.result);
                            setupdateCheckImage(reader.result);
                            setSnackOpen(false);
                          };
                        }

                        //console.log(e.target.files[0].size);
                      } catch (error) {
                        //console.log("error al procesar imagen", error);
                        setDeviceImage("");
                        setupdateCheckImage("");
                      }
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {deviceImage == "" ? (
                <Paper
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 250,
                    objectFit: "cover",
                    borderRadius: 16,
                    backgroundColor: blueGrey[50],
                    borderColor: purple[500],
                  }}
                >
                  <Grid container justifyContent="center" alignContent="center" direction="column" style={{ height: "100%" }}>
                    <Typography variant="h6" color="primary" style={{ alignSelf: "center" }}>
                      {t("imageBox")}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {t("imageBoxDefault")}
                    </Typography>
                  </Grid>
                </Paper>
              ) : (
                <img
                  style={{
                    width: "100%",
                    border: "solid",
                    borderColor: blueGrey[200],
                    borderWidth: 0.5,
                    height: 250,
                    objectFit: "cover",
                    borderRadius: 16,
                  }}
                  src={deviceImage}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={validateCheck} name="manualValidation" color="primary" />}
                label={t("Confirmo_que_los_datos_introducidos_son_correctos")}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">{t("imageLessThan10")}</Alert>
      </Snackbar>
    </Grid>
  );
};

const ProccesEnd = () => {
  return <Confetti height={window.height} width={window.width} style={{ height: "100vh" }} />;
};

const ProccessDialog = ({ mainOpen, title, content }) => {
  const [open, setopen] = useState(mainOpen);
  const { t } = useTranslation();

  useEffect(() => {
    setopen(mainOpen);
  }, [mainOpen]);

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setopen(false);
          }}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
