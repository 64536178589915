import { CompareArrowsOutlined, ExpandMore } from "@mui/icons-material";
import { Box, Button, Card, Container, Divider, Grid, ListItemText, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { blueGrey, lightBlue } from "@mui/material/colors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import * as ROUTES from "../constants/Routes";

const SoftwareVersionSteps = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [model, setModel] = useState(useParams().model == "R300" ? "Reanibex 300" : "Reanibex 500");
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [isCodeOk, setIsCodeOk] = useState("");
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Container style={{ paddingTop: 90 }}>
        {model !== "" && (
          <Grid item xs={12} container style={{ paddingBottom: 30 }} justifyContent={"space-between"} direction={"column"}>
            <Typography variant="h4">{t("tagVersionTitle")}</Typography>
            {model === "" && (
              <>
                <Button type="text" variant="contained" onClick={handleMenu} color="primary" style={{ marginLeft: 16 }}>
                  <Typography variant="h6"> {model}</Typography>
                  <ExpandMore />
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem button onClick={() => (setModel("Reanibex 300"), setAnchorEl(false))}>
                    <ListItemText>Reanibex 300</ListItemText>
                  </MenuItem>
                  <MenuItem button onClick={() => (setModel("Reanibex 500"), setAnchorEl(false))}>
                    <ListItemText>Reanibex 500</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>
        )}

        <Grid container spacing={2}>
          {model == "Reanibex 300" && <Config t={t} m={"3"} />}
          {model == "Reanibex 500" && <Config t={t} m={"5"} />}
        </Grid>
        {model !== "" && (
          <Grid item xs={12}>
            <Typography variant="body1">
              (*) {t("supportText")}
              <a href="mailto:support@bexencardio.com">support@bexencardio.com</a>
            </Typography>
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default SoftwareVersionSteps;

const Config = ({ t, m }) => (
  <Grid item xs={12} container spacing={2}>
    <Grid item xs={12} container justifyContent={"center"}>
      <Grid item xs={12} container>
        <Card style={{ padding: 16, borderRadius: 16 }}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
              {t("videoDemoTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 9 }}>
            <Typography>{t("softwareVideo", { model: m == "5" ? "Reanibex 500" : "Reanibex 300" })}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent={"center"}>
            <Grid item xs={12} md={8} lg={7} xl={6}>
              <video width="100%" controls style={{ borderRadius: 16, marginTop: 16 }}>
                <source
                  src={
                    m == "5"
                      ? "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR500-Tag-Version.mp4?alt=media&token=28e97956-e46a-404a-bc4c-0fd655b45c51"
                      : "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR300-Tag-version.mp4?alt=media&token=670d4afb-3af3-45c2-8963-bb01ea838e34"
                  }
                  type="video/mp4"
                />
              </video>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Divider>
        <div
          style={{
            border: "2px solid #9c27b0",
            width: 45,
            height: 45,
            lineHeight: 45,
            textAlign: "center",
            zIndex: 99,
            borderRadius: "50%",
            alignContent: "center",
          }}
        >
          <CompareArrowsOutlined
            color="secondary"
            style={{
              zIndex: 100,
              position: "absolute",
              transform: "rotate(90deg)",
              height: 38,
              width: 38,
              marginTop: 4,
              marginLeft: -19,
            }}
          />
        </div>
      </Divider>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("ConfigModeStepTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep1")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img
            src={require(m == "5" ? "../assets/img/R500-menu.jpg" : "../assets/img/R300-Menu.jpg")}
            style={{ width: "90%", marginTop: 16, borderRadius: 9 }}
          />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("SoftwareVersionsteptitle", { step: "2" })}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t(m == "5" ? "softwareVersionStep2" : "softwareVersionStep2R300")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img
            src={require(m == "5" ? "../assets/img/R500-maintenance.jpg" : "../assets/img/R300-maintenance.jpg")}
            style={{ width: "90%", marginTop: 16, borderRadius: 9 }}
          />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("SoftwareVersionsteptitle", { step: "3" })}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep3")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img
            src={require(m == "5" ? "../assets/img/R500-TagVersion.jpg" : "../assets/img/R300-TagVersion.jpg")}
            style={{ width: "90%", marginTop: 40, borderRadius: 9 }}
          />
        </Grid>
      </Card>
    </Grid>
  </Grid>
);

const R300Config = ({ t }) => (
  <>
    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("ConfigModeStepTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep1")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img src={require("../assets/img/R300-Menu.jpg")} style={{ width: "100%", marginTop: 16, borderRadius: 9 }} />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("SoftwareVersiontitle") + " 1"}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep2R300")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img src={require("../assets/img/R300-maintenance.jpg")} style={{ width: "100%", marginTop: 16, borderRadius: 9 }} />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("SoftwareVersiontitle") + " 2"}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep3")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img src={require("../assets/img/R300-TagVersion.jpg")} style={{ width: "100%", marginTop: 16, borderRadius: 9 }} />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("contactBexenCardio")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>
            {t("contactStep")}
            <Link to={ROUTES.SERIALNUMBER}>{t("contactHelper")}</Link>
          </Typography>
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={8} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("videoDemoTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareSerialNumberVideo", { model: "Reanibex 300" })}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <video width="95%" controls style={{ borderRadius: 16 }}>
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR300-Tag-version.mp4?alt=media&token=670d4afb-3af3-45c2-8963-bb01ea838e34"
              }
              type="video/mp4"
            />
          </video>
        </Grid>
      </Card>
    </Grid>
  </>
);
