import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  applyActionCode,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
const axios = require("axios").default;
const API = "https://support.bexencardio.com/"; // "http://localhost:8080/"; // "https://support.bexencardio.com/"; //*/ "https://support-test-bexencardio.azurewebsites.net/";

const config = {
  apiKey: "AIzaSyDEAbzsC6CKk28MFQdb757qLj8qF3i3kHo",
  authDomain: "r300-r500update.firebaseapp.com",
  projectId: "r300-r500update",
  storageBucket: "r300-r500update.appspot.com",
  messagingSenderId: "1050993529388",
  appId: "1:1050993529388:web:d0b34e42a0ea6f54657789",
};

class Firebase {
  constructor() {
    const app = initializeApp(config);
    this.auth = getAuth(app);
    this.app = app;
  }

  // *** Auth API ***
  currentUser = () => this.auth.currentUser;

  verifyCode = (oobCode) => applyActionCode(this.auth, oobCode);

  doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);

  requestPasswordChange = (email) => sendPasswordResetEmail(this.auth, email);

  verifyPasswordAndReset = (code, password) => confirmPasswordReset(this.auth, code, password);

  sendEmailToVerificateUser = () => sendEmailVerification(this.auth.currentUser);

  isDeviceUpdated = (serialNumber) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken(false)
        .then((token) => {
          axios
            .post(API + "api/checkUpdatedDevice", { token: token, serialNumber: serialNumber })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              //console.log(err);
              reject({ code: "request/error" });
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  doCreateUserWithEmailAndPassword = (email, password, name, phone, key, company, address, lang) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API + "api/checkFormKey", {
          code: key,
          email: email,
          password: password,
          name: name,
          phone: phone,
          company: company,
          address: address,
        })
        .then((res) => {
          if (res.data.code === "OK") {
            this.doSignInWithEmailAndPassword(email, password).then((userCredential) => {
              this.auth.languageCode = lang;

              sendEmailVerification(userCredential.user);
            });
            resolve();
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject();
        });
    });
  };

  doSignOut = () => signOut(this.auth);

  /* DEVICES API */

  checkSerial = (SN) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken(true)
        .then((token) => {
          axios
            .post(API + "api/checkSN", { token: token, serialNumber: SN })
            .then((res) => {
              if (res.data.verified) {
                resolve(res.data.updated);
              } else {
                //console.log('"devices/invalidSerial', res.data);
                reject({ code: "devices/invalidSerial", isNull: res.data.isNull });
              }
            })
            .catch((err) => {
              //console.log(err);
              reject({ code: "request/error" });
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  checkSorftVer = (SN, Vsof) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          axios.post(API + "api/checkSoftwareVersion", { token: token, serialNumber: SN, tagVersion: Vsof }).then((res) => {
            //console.log(res.data);
            if (res.data.verified) {
              resolve(res.data);
            } else {
              //console.log(res.data);
              reject({ code: res.data.code });
            }
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getSoftwareUpdateFile = (SN, Vsof) => {
    const self = this;
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          axios({
            url: API + "api/getSoftware",
            method: "POST",
            responseType: "blob", // important
            data: {
              token: token,
              serialNumber: SN,
              tagVersion: Vsof,
            },
          })
            .then((response) => {
              self
                .checkSorftVer(SN, Vsof)
                .then((snap) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  //console.log(snap);
                  link.setAttribute("download", snap.code.trim() + ".tag"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  resolve(snap.code.trim() + ".tag");
                })
                .catch((err) => {
                  //console.log(err);
                  reject(err);
                });
            })
            .catch((err) => {
              //console.log(err);
              reject(err);
            });
          /*axios.post(API + "api/getSoftware", { token: token, serialNumber: SN, tagVersion: Vsof }).then((res) => {
            //console.log(res.data);
            resolve(res.data);
          });*/
        })
        .catch((err) => {
          //console.log(err);
          reject(err);
        });
    });
  };

  checkUpdatedVersion = (oldTag, newTag, SN, updateCheckImage, manualValidation) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          axios
            .post(API + "api/checUpdatedVersion", {
              token: token,
              oldVerson: oldTag,
              newVersion: newTag,
              serialNumber: SN,
              image: updateCheckImage,
              manualValidation: manualValidation,
            })
            .then((res) => {
              //console.log(res.data);
              if (res.data.verified) {
                resolve(res.data);
              } else {
                //console.log(res.data);
                reject(res.data);
              }
            })
            .catch((err) => {
              reject({ code: "request/error" });
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getSystemStatics = () => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          axios.post(API + "api/statics", { token: token }).then((res) => {
            if (!res.data.error) {
              console.log(res.data);
              resolve(res.data);
            } else {
              reject();
            }
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getUserDeviceList = () => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          axios.post(API + "api/getUserDeviceList", { token: token }).then((res) => {
            if (res.data.correct) {
              resolve(res.data.data);
            } else {
              reject();
            }
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default Firebase;
