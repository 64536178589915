import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withFirebase } from "../Firebase";

const Verification = (props) => {
  const { firebase } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [errorOnSend, setErrorOnSend] = useState(false);

  const sendVerificationEmail = () => {
    firebase
      .sendEmailToVerificateUser()
      .then(() => {
        setOpen(true);
        setErrorOnSend(false);
      })
      .catch((err) => {
        setOpen(true);
        setErrorOnSend(true);
      });
  };
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "100vh" }}>
      <Grid item xs={12} container spacing={4}>
        <Grid item xs={12} container justifyContent={"center"}>
          <Typography variant="h4">{t("verifica_el_email_para_continuar")}</Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container justifyContent={"center"}>
            <Typography variant="body1">
              {t("si_no_le_ha_llegado_el_email_de_verificación_o_bien_necesita_uno_nuevo,_pulse_el_botón_para_enviarlo_nuevamente")}
            </Typography>
          </Grid>

          <Grid item xs={12} container justifyContent={"center"}>
            <Button variant="outlined" style={{ textTransform: "none" }} onClick={sendVerificationEmail}>
              {t("enviar_email_de_validación")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setOpen(false)} severity={errorOnSend ? "error" : "success"} sx={{ width: "100%" }}>
          {errorOnSend
            ? t("errorOnSendVerificationEmail")
            : t("successOnSendVerificationEmail", { email: !!firebase.currentUser() && firebase.currentUser().email })}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default withFirebase(Verification);
