import { Button, Card, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import React, { isValidElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../constants/Routes";
import * as PATTERNS from "../constants/patterns";
import LangSelect from "../components/LangSelect";
import { Home } from "@mui/icons-material";

const SignIn = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { firebase } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [doingLogin, setDoingLogin] = useState(false);

  const logIn = (e) => {
    e.preventDefault();
    setDoingLogin(true);
    firebase.doSignInWithEmailAndPassword(email, password).catch((err) => {
      setFormError({ email: t("invalidLogIn"), psw: t("invalidLogIn") });
      setDoingLogin(false);
    });
  };

  const isValid = email !== "" && password != "" && Object.keys(formError).every((k) => formError[k] === null);

  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged((authUser) => {
      !!authUser && navigate(ROUTES.HOME);
    });

    return () => {
      listener();
    };
  }, []);

  const updateformError = (aux) => {
    var elem = {};
    Object.keys(aux).forEach((k) => (elem[k] = aux[k]));
    setFormError(elem);
  };

  const checkNotNull = (value, field) => {
    var aux = formError;
    value = value.trim();
    aux[field] = value == "" ? t("notEmptyField") : null;
    updateformError(aux);
  };

  const checkEmail = (email) => {
    var aux = formError;
    const rege = new RegExp(PATTERNS.EMAIL);
    if (email == "") {
      checkNotNull(email, "email");
    } else {
      aux["email"] = !rege.test(email) ? t("invalidEmailFormt") : null;
    }
    updateformError(aux);
  };

  return (
    <Container>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          flex: 1,
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        <Button
          style={{ position: "absolute", top: 24, left: 64 }}
          startIcon={<Home />}
          onClick={() => {
            navigate(ROUTES.LANDING_PAGE);
          }}
        >
          {t("home")}
        </Button>
        <Grid container item xs={12} md={6} lg={5} xl={4}>
          <Card variant="outlined" style={{ padding: 24, borderRadius: 16 }}>
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12} container direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h6">{t("signIn")} *</Typography>
                <LangSelect />
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={logIn}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label={t("email")}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setFormError({});
                        }}
                        helperText={formError.email}
                        error={!!formError.email}
                        onBlur={(e) => checkEmail(e.target.value)}
                        type={"email"}
                        required
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="pass1"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setFormError({});
                        }}
                        label={t("password")}
                        helperText={formError.psw}
                        error={!!formError.psw}
                        type={"password"}
                        required
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        style={{ textTransform: "none" }}
                        disabled={!isValid || doingLogin}
                        onClick={logIn}
                      >
                        {t("signIn")}
                        {doingLogin && (
                          <CircularProgress
                            size={24}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: -12,
                              marginLeft: -12,
                            }}
                          />
                        )}
                      </Button>
                      <Button
                        fullWidth
                        variant="text"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          navigate(ROUTES.RECOVERPASSWORD);
                        }}
                      >
                        {t("passwordForgot?")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth style={{ textTransform: "none" }} variant="outlined" onClick={() => navigate(ROUTES.LANDING_PAGE)}>
                  {t("createAccount")}
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Typography variant="body2" style={{ margin: 9, textAlign: "center" }}>
            (*) {t("supportText")} support@bexencardio.com
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withFirebase(SignIn);
