import { CompareArrowsOutlined, ExpandMore } from "@mui/icons-material";
import { Button, Card, Container, Divider, Grid, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { lightBlue, purple } from "@mui/material/colors";
import zIndex from "@mui/material/styles/zIndex";
import { height } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LangSelect from "../components/LangSelect";
import SerialNumberCheck from "../components/SerialNumberCheck";

const SerialNumberSteps = () => {
  const { t } = useTranslation();
  const [model, setModel] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container style={{ minHeight: "100vh" }}>
      {model == "" && <ModelSelector setModel={setModel} t={t} />}
      {model !== "" && (
        <Grid item xs={12} style={{ paddingTop: 54, marginBottom: 18, paddingBottom: 18 }}>
          <SerialNumberCheck />
        </Grid>
      )}
      {model !== "" && (
        <Grid item xs={12} container style={{ marginTop: 18 }}>
          <Typography variant="h4" style={{ marginBottom: 18 }}>
            {t("serialNumberTitle")}
          </Typography>
          {model !== "" && (
            <>
              <Button type="text" variant="outlined" onClick={handleMenu} color="primary" style={{ marginLeft: 16, height: 42 }}>
                <Typography variant="h6"> {model}</Typography>
                <ExpandMore />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem button onClick={() => (setModel("Reanibex 300"), setAnchorEl(false))}>
                  <ListItemText>Reanibex 300</ListItemText>
                </MenuItem>
                <MenuItem button onClick={() => (setModel("Reanibex 500"), setAnchorEl(false))}>
                  <ListItemText>Reanibex 500</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      )}
      {model == "Reanibex 500" && <Serial t={t} m={"5"} />}
      {model == "Reanibex 300" && <Serial t={t} m={"3"} />}
    </Container>
  );
};
export default SerialNumberSteps;

const Serial = ({ t, m }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6} lg={4} container>
      <Typography variant="h5" style={{ fontWeight: 600, color: purple[500] }}>
        {t("videoDemoTitle")}
      </Typography>
    </Grid>
    <Grid item xs={12} md={6} lg={8} container>
      <Typography variant="h5" style={{ fontWeight: 600, color: purple[500] }}>
        {t("stepbyStep")}
      </Typography>
    </Grid>
    <Grid item xs={12} md={6} lg={3} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareSerialNumberVideo", { model: m == "5" ? "Reanibex 500" : "Reanibex 300" })}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <video width="95%" controls style={{ borderRadius: 16, marginTop: 16 }}>
            <source
              src={
                m == "5"
                  ? "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR500-SerialNumber.mp4?alt=media&token=6c66cc7d-6d62-47e4-849b-13590a5949cf"
                  : "https://firebasestorage.googleapis.com/v0/b/reanibex-training.appspot.com/o/video%2FR300-SerialNumber.mp4?alt=media&token=1ec1618e-82a0-4984-acf6-aeb3cabb6344"
              }
              type="video/mp4"
            />
          </video>
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} md={1} container justifyContent={"center"}>
      <Divider orientation="vertical">
        <div
          style={{
            border: "2px solid #9c27b0",
            width: 45,
            height: 45,
            lineHeight: 45,
            textAlign: "center",
            zIndex: 99,
            borderRadius: "50%",
            alignContent: "center",
          }}
        >
          <CompareArrowsOutlined
            color="secondary"
            style={{
              zIndex: 100,
              position: "absolute",
              height: 38,
              width: 38,
              marginTop: 4,
              marginLeft: -19,
            }}
          />
        </div>
      </Divider>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("ConfigModeStepTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t("softwareVersionStep1")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img
            src={require(m == "5" ? "../assets/img/R500-menu.jpg" : "../assets/img/R300-Menu.jpg")}
            style={{ width: "85%", marginTop: 16, borderRadius: 9 }}
          />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12} lg={4} container>
      <Card style={{ padding: 16, borderRadius: 16 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600, color: lightBlue[500] }}>
            {t("ConfigModeSNStepTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 9 }}>
          <Typography>{t(m == "5" ? "softwareSerialNumberStep" : "softwareSerialNumberStepR300")}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <img
            src={require(m == "5" ? "../assets/img/R500-serialNumber.jpg" : "../assets/img/R300-SerialNumber.jpg")}
            style={{ width: "85%", marginTop: 16, borderRadius: 9 }}
          />
        </Grid>
      </Card>
    </Grid>

    <Grid item xs={12}>
      <DifficultText t={t} />
    </Grid>
  </Grid>
);

const DifficultText = ({ t }) => (
  <Typography variant="body1">
    (*) {t("supportText")}
    <a href="mailto:support@bexencardio.com">support@bexencardio.com</a>
  </Typography>
);

const ModelSelector = ({ setModel, t }) => (
  <Grid container spacing={4} justifyContent={"center"} style={{ paddingTop: 100 }}>
    <Grid item xs={12} container justifyContent={"center"} style={{ paddingBottom: 64 }}>
      <Typography variant="h3">{t("chooseDeviceModel")}</Typography>
    </Grid>
    <Grid item xs={12} container justifyContent={"space-around"}>
      <Grid item xs={12} md={4} container justifyContent={"center"}>
        <Typography
          variant="h4"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModel("Reanibex 300");
          }}
        >
          Reanibex 300
        </Typography>
        <img
          src={require("../assets/img/Reanibex-300-DEA.png")}
          onClick={() => {
            setModel("Reanibex 300");
          }}
          style={{
            width: 280,
            cursor: "pointer",
            marginTop: 16,
            borderRadius: 16,
            margin: 16,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8} container justifyContent={"center"}>
        <Typography
          variant="h4"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModel("Reanibex 500");
          }}
        >
          Reanibex 500
        </Typography>
        <div>
          <img
            src={require("../assets/img/R500.png")}
            onClick={() => {
              setModel("Reanibex 500");
            }}
            style={{
              width: 386,
              marginTop: 16,
              cursor: "pointer",
              borderRadius: 16,
              margin: 16,
              marginRight: -60,
            }}
          />
          <img
            src={require("../assets/img/R500-w.png")}
            onClick={() => {
              setModel("Reanibex 500");
            }}
            style={{
              width: 295,
              marginTop: 16,
              cursor: "pointer",
              borderRadius: 16,
              margin: 16,
              marginLeft: -60,
            }}
          />
        </div>
      </Grid>
    </Grid>
  </Grid>
);
