import React from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LangSelect from "./LangSelect";
import AuthUserContext from "./Session/context";
import SignOut from "./SignOut";

const Navigation = () => {
  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <AppBar position="absolute">
            <Toolbar>
              <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} disabled></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Bexen Cardio Support
              </Typography>
              <LangSelect />
              <SignOut />
            </Toolbar>
          </AppBar>
        ) : null
      }
    </AuthUserContext.Consumer>
  );
};

export default Navigation;
