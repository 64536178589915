import { FormatOverline, InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import * as PATTERNS from "../constants/patterns";
import { withFirebase } from "../Firebase";
import LangSelect from "./LangSelect";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterForm = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { firebase } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [psw1, setPsw1] = useState("");
  const [psw2, setPsw2] = useState("");
  const [psw2focused, setPsw2focused] = useState(false);
  const [key, setKey] = useState("");
  const [phone, setPhone] = useState("");
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [requestError, setRequestError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [policyAccept, setPolicyAccept] = useState(false);

  const checkNotNull = (value, field) => {
    var aux = formError;
    value = value.trim();
    aux[field] = value == "" ? t("notEmptyField") : null;
    updateformError(aux);
  };

  const updateformError = (aux) => {
    var elem = {};
    Object.keys(aux).forEach((k) => (elem[k] = aux[k]));
    setFormError(elem);
  };

  const checkEmail = (email) => {
    var aux = formError;
    const rege = new RegExp(PATTERNS.EMAIL);
    if (email == "") {
      checkNotNull(email, "email");
    } else {
      aux["email"] = !rege.test(email) ? t("invalidEmailFormt") : null;
    }
    updateformError(aux);
  };

  const checkPhone = (value) => {
    var aux = formError;
    const rege1 = new RegExp("^[+]?[(]?[0-9]{1,3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$");
    const rege2 = new RegExp("^([+]*[(][0-9]{1,3}[)])?([+]*[0-9]{2})?[\\s]?([0-9]{2,4}[\\s.-]?){2,4}$");

    if (value !== "") {
      if (rege1.test(value) || rege2.test(value)) {
        aux["phoneNumber"] = null;
      } else {
        aux["phoneNumber"] = t("invalidPhoneFormt");
      }
      // necesario para actualiar el estado en el mismo ciclo
      updateformError(aux);
    } else {
      aux["phoneNumber"] = null;
      updateformError(aux);
    }
  };

  const checkPassword = (pw1, pw2) => {
    var aux = formError;
    if (psw1.length < 6 && psw1 != "") {
      aux["psw1"] = t("lengthlessthan6");
    } else if (psw2 !== psw1 && psw2focused) {
      aux["psw1"] = t("passwordNoMatch");
      aux["psw2"] = t("passwordNoMatch");
    } else {
      aux["psw1"] = null;
      aux["psw2"] = null;
    }
    updateformError(aux);
  };

  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged((authUser) => {
      !!authUser && navigate(ROUTES.HOME);
    });

    return () => {
      listener();
    };
  }, []);

  const isValid =
    name !== "" &&
    email !== "" &&
    key !== "" &&
    psw1 !== "" &&
    psw2focused &&
    psw1 === psw2 &&
    Object.keys(formError).every((k) => formError[k] === null);

  const CreateUser = () => {
    setLoading(true);
    firebase
      .doCreateUserWithEmailAndPassword(email, psw1, name, phone, key, company, address, i18n.language)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err === undefined) {
          setRequestError(true);
        } else {
          var aux = formError;
          switch (err.code) {
            case "auth/invalid-phone-number":
              aux["phoneNumber"] = t(err.code);
              break;
            /*case "auth/invalidCode":
              aux["key"] = t(err.code);
              break;*/
            case "auth/email-already-exists":
              aux["email"] = t(err.code);
              break;
            case "auth/invalid-email":
              aux["email"] = t(err.code);
              break;
            case "auth/invalid-display-name":
              aux["name"] = t(err.code);
              break;
            case "auth/invalid-password":
              aux["psw1"] = t(err.code);
              aux["psw2"] = t(err.code);
              break;
            case "auth/addressTooLong":
              aux["address"] = t(err.code);
              break;
            case "auth/companyTooLong":
              aux["company"] = t(err.code);
              break;
            case "request/error":
              setRequestError(true);
              break;
          }
          updateformError(aux);
        }
        setTimeout(() => {
          setLoading(false);
        }, 750);
      });
  };

  const handleClose = () => {
    setRequestError(false);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} container direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" color={"GrayText"}>
            {t("createYourAccount")} *
          </Typography>
          <LangSelect />
        </Grid>
        {/*
          <Grid item xs={12} sm={6} md={12}>
            <FormControl fullWidth>
              <InputLabel error={!!formError.key}>{t("code")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={key}
                error={!!formError.key}
                onChange={(e) => {
                  e = e.target.value;
                  setKey(e);
                  !!formError.key && checkNotNull(e, "key");
                }}
                onBlur={() => {
                  setKey(key.trim());
                  checkNotNull(key, "key");
                }}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={<Typography>{t("keyNoteTooltip")}</Typography>}>
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
                label={t("code")}
              />
              <FormHelperText error>{formError.key}</FormHelperText>
            </FormControl>
          </Grid>
            */}
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            label={t("name")}
            variant="outlined"
            value={name}
            required
            onBlur={() => {
              setName(name.trim());
              checkNotNull(name, "name");
            }}
            helperText={formError.name}
            error={!!formError.name}
            onChange={(e) => {
              e = e.target.value;
              !!e && setName(e.slice(0, 69));
              !!formError.name && checkNotNull(e, "name");
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phone"
            value={phone}
            helperText={formError.phoneNumber}
            error={!!formError.phoneNumber}
            onBlur={(e) => {
              checkPhone(e.target.value);
            }}
            onChange={(e) => {
              e = e.target.value;
              setPhone(e);
              !!formError.phoneNumber && checkPhone(e);
            }}
            type="tel"
            label={t("phone")}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <TextField
            id="company"
            value={company}
            helperText={formError.company}
            error={!!formError.company}
            label={t("company")}
            variant="outlined"
            onChange={(e) => {
              setCompany(e.target.value); //.slice(0, 254)
              let aux = formError;
              aux["company"] = e.target.value.length > 254 ? t("auth/companyTooLong") : null;
              updateformError(aux);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <TextField
            id="address"
            value={address}
            helperText={formError.address}
            error={!!formError.address}
            label={t("address")}
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value);
              let aux = formError;
              aux["company"] = e.target.value.length > 254 ? t("auth/companyTooLong") : null;
              updateformError(aux);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <TextField
            id="email"
            type={"email"}
            label={t("email")}
            required
            variant="outlined"
            value={email}
            helperText={formError.email}
            error={!!formError.email}
            onChange={(e) => {
              setEmail(e.target.value);
              !!formError.email && checkEmail(e.target.value);
            }}
            onBlur={() => checkEmail(email)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error={!!formError.psw1} fullWidth>
            <InputLabel required>{t("password")}</InputLabel>
            <OutlinedInput
              id="pass1"
              label={t("password")}
              type={showPassword ? "text" : "password"}
              required
              variant="outlined"
              fullWidth
              value={psw1}
              error={!!formError.psw1}
              onChange={(e) => {
                e = e.target.value;
                setPsw1(e);
                !!formError.psw1 && checkPassword(e, psw2);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onBlur={() => checkPassword(psw1, psw2)}
            />
            <FormHelperText error>{formError.psw1}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error={!!formError.psw2} fullWidth>
            <InputLabel required error={!!formError.psw2}>
              {t("repeatPassword")}
            </InputLabel>
            <OutlinedInput
              id="pass2"
              label={t("repeatPassword")}
              type={showPassword ? "text" : "password"}
              required
              variant="outlined"
              fullWidth
              value={psw2}
              error={!!formError.psw2}
              onChange={(e) => {
                e = e.target.value;
                setPsw2(e);
                setPsw2focused(true);
                !!formError.psw2 && checkPassword(psw1, e);
              }}
              onBlur={() => checkPassword(psw1, psw2)}
              onFocus={() => {
                setPsw2focused(true);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{formError.psw2}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={policyAccept} onChange={() => setPolicyAccept(!policyAccept)} name="checkedB" color="primary" />}
            label="Primary"
          />
        </Grid>
        {
          <Grid item xs={12} container justifyContent={"center"}>
            <ReCAPTCHA
              size="normal"
              sitekey="6Lc4AIYgAAAAAA8pgWBU12aWmXMTo8N3YVM5XgwU"
              style={{ alignSelf: "center" }}
              onChange={(token) => {
                setKey(token);
              }}
              onError={() => setKey("")}
              onExpired={() => setKey("")}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <Button fullWidth variant="contained" disabled={!isValid || loading} onClick={CreateUser}>
            {t("createAccount")}
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Button>
          <Typography variant="body2" color={"GrayText"} style={{ marginTop: 6 }}>
            {t("aceptPolicy")}
            {
              <Link
                href="#"
                underline="none"
                onClick={() => {
                  navigate(ROUTES.POLICY);
                }}
              >
                {t("privacyPolicy")}
              </Link>
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="text"
          onClick={() => {
            navigate(ROUTES.SIGN_IN);
          }}
        >
          {t("alreadyHaveAnAccount")}
        </Button>
      </Grid>
      <Snackbar open={requestError} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {t("request/error")}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default withFirebase(RegisterForm);
