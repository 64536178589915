export const LANDING_PAGE = "/";
export const SIGN_IN = "/signin";
export const PASSWORD_FORGET = "/pw-forget";
export const HOME = "/home";
export const UPDATE = "/update-device";
export const SERIALNUMBER = "/serialnumber";
export const SOFWAREVERSION = "/software-version/:model";
export const VERIFY = "/verify";
export const RECOVERPASSWORD = "/recoverPSW";
export const CONTINUEUPDATE = "/resume/:serialNumber/:softwareVersion";
export const DASHBOARD = "/summary";
export const POLICY = "/policy";
