import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../../Firebase";
import { CircularProgress, Grid } from "@mui/material";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        loaded: false,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.setState({ authUser: authUser, loaded: true });
        } else {
          this.setState({ authUser: null, loaded: true });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      if (!this.state.loaded) {
        return (
          <Grid container justifyContent={"center"} alignItems="center" style={{ height: "100vh" }}>
            <CircularProgress size={80} color="primary" />
          </Grid>
        );
      } else {
        //console.log("usuario", this.state.authUser);
        return (
          <AuthUserContext.Provider value={this.state.authUser}>
            <Component {...this.props} />
          </AuthUserContext.Provider>
        );
      }
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
