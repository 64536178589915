import { Home } from "@mui/icons-material";
import { Button, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";

const Policy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Button
        style={{ position: "absolute", top: 24, left: 64 }}
        startIcon={<Home />}
        onClick={() => {
          navigate(ROUTES.LANDING_PAGE);
        }}
      >
        {t("home")}
      </Button>
      <Grid container alignItems={"center"} justifyContent={"center"} style={{ minHeight: "100vh", paddingTop: 74 }}>
        <Grid item xs={12} md={10} lg={7}>
          <Card style={{ padding: 16, borderRadius: 16 }}>
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              Política de privacidad
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify", marginTop: 16 }}>
              De conformidad con lo establecido en la normativa vigente en Protección de Datos de Carácter Personal, le informamos que sus
              datos serán incorporados al sistema de tratamiento titularidad de OSATU S.COOP. con CIF F48113161 y domicilio social sito en
              EDIFICIO ZEARREKOBUELTA, SUBIDA DE AREITIO, 5, 48260 ERMUA (VIZCAYA), con la finalidad de atender sus consultas y remitirle
              información relacionada que pueda ser de su interés. En cumplimiento con la normativa vigente, OSATU S.COOP informa que los
              datos serán conservados durante el plazo estrictamente necesario para cumplir con los preceptos mencionados con anterioridad.
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify" }}>
              Mientras no nos comunique lo contrario, entenderemos que sus datos no han sido modificados, que usted se compromete a
              notificarnos cualquier variación y que tenemos su consentimiento para utilizarlos para las finalidades mencionadas.
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify" }}>
              OSATU S.COOP informa que procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada,
              exacta y actualizada. Es por ello que OSATU S.COOP se com promete a adoptar todas las medidas razonables para que estos se
              supriman o rectifiquen sin dilación cuando sean inexactos.
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify" }}>
              De acuerdo con los derechos que le confiere el la normativa vigente en protección de datos podrá ejercer los derechos de
              acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratam iento de sus datos de carácter
              personal así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal
              indicada más arriba o al correo electrónico INFOBEXENCARDIO.COM.
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify" }}>
              Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que considere oportuna.
            </Typography>
            <Typography variant="body2" color="GrayText" style={{ textAlign: "justify" }}>
              Con envío del formulario de recogida de datos usted acepta la política de privacidad de OSATU S.COOP.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Policy;
