import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";

const PermissionDenied = () => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} style={{ minHeight: "100vh" }} direction={"column"}>
      <Typography>No tienes permiso para acceder a este recurso</Typography>
      <Button
        onClick={() => {
          navigate(ROUTES.SIGN_IN);
        }}
      >
        Iniciar Sesión
      </Button>
      <Button
        onClick={() => {
          navigate(ROUTES.LANDING_PAGE);
        }}
      >
        Ir al inicio
      </Button>
    </Grid>
  );
};

export default PermissionDenied;
