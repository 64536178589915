import {
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withAuthorization } from "../components/Session";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import { useTranslation } from "react-i18next";
import { SearchOutlined, WarningRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import { blue, grey, lightBlue } from "@mui/material/colors";

const Home = (props) => {
  const navigate = useNavigate();
  const { firebase } = props;
  const { t } = useTranslation();

  const [updatedDevices, setUpdatedDevices] = useState([]);
  const [userDevicesList, setUserDevicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [matchText, setMatchText] = useState("");
  const [toProccess, setToProccess] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    firebase.getUserDeviceList().then((snap) => {
      // console.log(snap.user.equipos);
      setUserDevicesList(snap.user.equipos);
      setUpdatedDevices(snap.user.equipos);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (matchText !== "") {
      setUpdatedDevices(userDevicesList.filter((d) => d.includes(matchText)));
      setIsSearching(true);
    } else {
      setUpdatedDevices(userDevicesList);
      setIsSearching(false);
    }
  }, [matchText]);

  return (
    <Grid container justifyContent={"center"} alignContent={"flex-start"} style={{ minHeight: "100vh", paddingTop: "10vh" }}>
      <Container>
        <Grid item xs={12} container spacing={2} style={{ paddingTop: 34 }}>
          <Grid item xs={12}>
            <Typography variant="h5" color={"primary"}>
              {t("updateNewDevice")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={5} container>
            <Card style={{ padding: 16, borderRadius: 9 }}>
              <Typography variant="h6">{t("necessaryMaterials")}</Typography>
              <Grid item xs={12} container spacing={4} justifyContent={"center"}>
                <Grid item xs={6} container justifyContent={"center"} alignItems={"flex-end"}>
                  <img src={require("../assets/img/R500.png")} style={{ width: "95%", marginTop: 16 }} />
                  <Typography style={{ textAlign: "center" }}>Reanibex 300 - Reanibex 500</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  direction={"row"}
                  style={{ textAlign: "center" }}
                >
                  <img src={require("../assets/img/memoria-usb.png")} style={{ width: "60%", marginTop: 24, transform: "rotate(-45deg" }} />
                  <Typography style={{ textAlign: "center" }}>{t("emptyUSB")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="pulse"
                    fullWidth
                    onClick={() => {
                      setToProccess(true);
                      setTimeout(() => {
                        navigate(ROUTES.UPDATE);
                      }, 500);
                    }}
                  >
                    {t("updateNewDevice")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} style={{ paddingTop: 58 }}>
          <Grid item xs={12} container alignItems={"flex-end"}>
            <Typography variant="h5" color={"primary"}>
              {t("updatedDevices")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 32 }}>
            <Card style={{ padding: 16, borderRadius: 16 }}>
              <Grid container spacing={1} justifyContent={updatedDevices.length == 0 ? "center" : "flex-start"}>
                {(updatedDevices.length > 0 || isSearching) && (
                  <Grid item xs={12}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        maxWidth: 400,
                        marginBottom: 2,
                        backgroundColor: blue[50],
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={t("searchBySerial")}
                        inputProps={{ "aria-label": t("searchBySerial") }}
                        value={matchText}
                        onChange={(e) => setMatchText(e.target.value.slice(0, 8))}
                      />
                      <IconButton sx={{ p: "10px" }} aria-label="search">
                        <SearchOutlined />
                      </IconButton>
                    </Paper>
                  </Grid>
                )}
                {updatedDevices.length == 0 && !isLoading ? (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    container
                    alignContent={"center"}
                    justifyContent={"center"}
                    direction={"column"}
                    style={{ minHeight: 32 }}
                  >
                    <Typography variant="h6" style={{ textAlign: "center", fontSize: "1.55rem" }}>
                      {t("noUpdatedDevicesFound")}
                    </Typography>
                    <img src={require("../assets/img/Not_Found.PNG")} style={{ maxWidth: "95%" }} />
                  </Grid>
                ) : isLoading ? (
                  [0, 1, 2, 3, 4, 5].map((s) => <UserDevice serial={s} loading />)
                ) : (
                  updatedDevices.map((s) => <UserDevice serial={s} firebase={firebase} />)
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Modal open={toProccess}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress size={64} thickness={4.5} style={{ color: "white" }} />
        </Box>
      </Modal>
    </Grid>
  );
};

const condition = (authUser) => authUser.emailVerified;
export default withAuthorization(condition)(Home);

const UserDevice = ({ serial, loading, firebase }) => {
  const { t } = useTranslation();

  const [updated, setUpdated] = useState(false);
  const [tag, setTag] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toProccess, setToProccess] = useState(false);

  !!firebase &&
    firebase.isDeviceUpdated(serial).then((r) => {
      setUpdated(r.hasUpdated);
      setTag(r.tag);
    });

  return (
    <Grid item xs={6} lg={2}>
      <Tooltip hidden title={!updated && <Typography variant="body1">{t("resumeUpdateTooltip")}</Typography>}>
        <Button
          fullWidth
          variant="outlined"
          color={updated ? "success" : "warning"}
          style={{ padding: 16, borderRadius: 16 }}
          onClick={() => {
            updated && setOpen(true);
            !updated && setToProccess(true);
            !updated &&
              setTimeout(() => {
                navigate(ROUTES.CONTINUEUPDATE.replace(":serialNumber", serial).replace(":softwareVersion", tag));
              }, 500);
          }}
        >
          <Grid item xs={12} container alignItems={"center"} direction={"column"}>
            {loading ? (
              <Skeleton variant="rectangular" width={113} height={113} />
            ) : (
              <img
                src={serial.charAt(3) == "5" ? require("../assets/img/R500.png") : require("../assets/img/Reanibex-300-DEA.png")}
                style={{ width: serial.charAt(3) == "5" ? "95%" : "75%", marginTop: 8, marginBottom: 8 }}
              />
            )}
            <Grid container alignItems={"center"} justifyContent={"center"}>
              {!updated && !loading && <WarningRounded style={{ paddingRight: 6 }} />}
              <Typography variant="body1" style={{ fontWeight: 700, textAlign: "center" }}>
                {loading ? <Skeleton width={113} height={24} /> : `SN ${serial}`}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Tooltip>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          {!loading && <Typography>{t("deviceSuccessUpdate", { model: serial.charAt(3) == "5" ? "500" : "300" })}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={toProccess}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress size={64} thickness={4.5} style={{ color: "white" }} />
        </Box>
      </Modal>
    </Grid>
  );
};
