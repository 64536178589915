import { Button, Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import { useTranslation } from "react-i18next";

const SignOut = (props) => {
  const { firebase } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const signOut = () => {
    firebase.doSignOut();
  };
  const close = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="Sign out"
        onClick={() => {
          setOpen(true);
        }}
        style={{ color: "#fff" }}
      >
        <PowerSettingsNew />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle>{t("closeSession")}</DialogTitle>
        <DialogActions>
          <Button variant="text" onClick={close}>
            {t("no")}
          </Button>
          <Button variant="contained" onClick={signOut}>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withFirebase(SignOut);
