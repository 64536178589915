import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";

const HomeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTES.LANDING_PAGE);
  }, []);

  return <CircularProgress />;
};

export default HomeRedirect;
