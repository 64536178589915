import { Alert, Backdrop, Button, Card, CircularProgress, Container, Grid, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import { withFirebase } from "../Firebase";
import * as PATTERNS from "../constants/patterns";
import LangSelect from "../components/LangSelect";
import { ArrowBack, Home, HomeOutlined } from "@mui/icons-material";

const RecoverPassword = (props) => {
  const { firebase } = props;
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [email, setEmail] = useState("");
  const [helperText, setHelperText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sentEmailText, setSentEmailText] = useState("");

  const requestPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (PATTERNS.EMAIL.test(email)) {
      firebase
        .requestPasswordChange(email)
        .then(() => {
          setOpen(true);
          setLoading(false);
          setSentEmailText(t("emailSendTo", { email: email }));
          setEmail("");
        })
        .catch((err) => {
          setHelperText(err.code);
          setLoading(false);
        });
    } else {
      setHelperText();
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setHelperText("");
  }, [email]);

  const isEmailValid = PATTERNS.EMAIL.test(email);

  return (
    <Container>
      <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "100vh" }}>
        <Grid item xs={12} md={6} lg={5}>
          <Button
            style={{ position: "absolute", top: 24, left: 64 }}
            startIcon={<ArrowBack />}
            onClick={() => {
              navigation(ROUTES.SIGN_IN);
            }}
          >
            {t("back")}
          </Button>

          <Card style={{ padding: 16, borderRadius: 16 }}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} container direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h6" color={"primary"}>
                  {t("passwordReset")} *
                </Typography>
                <LangSelect />
              </Grid>
              <Grid item xs={12}>
                <Typography color={"GrayText"}>{t("enterEmailtoRequestPasswordChange")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={requestPassword}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={9}>
                      <TextField
                        id="key"
                        type={"email"}
                        label={t("email")}
                        value={email}
                        helperText={t(helperText)}
                        error={helperText !== ""}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <Button
                        variant="outlined"
                        fullWidth
                        type="submit"
                        style={{ height: 56 }}
                        disabled={!isEmailValid || loading || helperText != ""}
                        onClick={requestPassword}
                      >
                        {t("requestPasswordReset")}
                        {loading && (
                          <CircularProgress
                            size={24}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: -12,
                              marginLeft: -12,
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Card>
          <Typography variant="body2" style={{ margin: 9, textAlign: "center" }}>
            (*) {t("supportText")} support@bexencardio.com
          </Typography>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {sentEmailText}
          </Alert>
        </Snackbar>
      </Grid>
    </Container>
  );
};

export default withFirebase(RecoverPassword);
