import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
  TextField,
  CircularProgress,
} from "@mui/material";
import { amber, blue, green, grey, lightGreen, pink, purple, red } from "@mui/material/colors";
import {
  ArticleOutlined,
  BrowserUpdatedOutlined,
  ErrorOutlined,
  GppGoodOutlined,
  PersonOutline,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import moment from "moment";

import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, BarElement } from "chart.js";
import { withAuthorization } from "../components/Session";
Chart.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, BarElement);

function Dashboard(props) {
  const { firebase } = props;

  const [statics, setStatics] = useState({ usersAgg: [{ x: 0, y: 0 }], lastLogs: [] });
  const [serialToFound, setSerialToFound] = useState("");
  const [erronrOnSerieal, seterronrOnSerieal] = useState(false);
  const [isSerialUpdated, setIsSerialUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    firebase.getSystemStatics().then((snap) => {
      !!snap && setStatics(snap);
    });
  }, []);

  const checkSN = (sn) => {
    console.log("------", sn);
    sn = sn.charAt(4) === "/" ? sn.split("/")[1] : sn;
    const regx500 = "^2005[0-9]{4,4}$";
    const regx500Old = "^9004[0-9]{4,4}$";
    const regx300 = "^2004[0-9]{4,4}$";

    //console.log(sn, new RegExp(regx500).test(serialNumber), new RegExp(regx300).test(serialNumber));

    if (sn == "") {
      seterronrOnSerieal(true);
      setIsSerialUpdated(null);
    } else {
      if (!new RegExp(regx500).test(sn) && !new RegExp(regx300).test(sn) && !new RegExp(regx500Old).test(sn)) {
        seterronrOnSerieal(false);
        setIsSerialUpdated(false);
      } else if (new RegExp(regx500Old).test(sn)) {
        setIsSerialUpdated(true);
      } else {
        seterronrOnSerieal(true);
        setIsLoading(true);
        firebase
          .isDeviceUpdated(sn)
          .then((r) => {
            console.log(r);
            r.hasUpdated ? setIsSerialUpdated(true) : setIsSerialUpdated(false);
            // r.hasUpdated !== undefined ? setIsSerialUpdated(r.hasUpdated) : setIsSerialUpdated(false);
            setIsLoading(false);
          })
          .catch(() => {
            setIsSerialUpdated(false);
            setIsLoading(false);
          });
      }
    }
  };

  const onChange = (e) => {
    setSerialToFound(e.target.value.trim().slice(0, 8));
    e.target.value.trim().slice(0, 8).length == 8 && checkSN(e.target.value.trim().slice(0, 8));
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Grid container justifyContent={"center"} style={{ paddingTop: 80 }}>
        <Grid container item xs={12} lg={10} spacing={3}>
          <Grid item lg={3} container>
            <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
              <Grid container direction={"row"}>
                <Grid item xs={6}>
                  <Typography variant="h6" color="GrayText" style={{ fontWeight: 400, paddingBottom: 12 }}>
                    Equipos
                  </Typography>
                  <Typography variant="h4" color="GrayText" style={{ fontWeight: 400, paddingBottom: 6 }}>
                    {statics.totalDevices}
                  </Typography>
                  <Chip
                    label={moment(new Date()).format(`DD/MM hh:mm`)}
                    size="small"
                    style={{ backgroundColor: purple[50], color: purple[300], fontWeight: 800 }}
                  />
                </Grid>
                <Grid item xs={6} container alignItems={"flex-end"} justifyContent={"flex-end"}>
                  <BrowserUpdatedOutlined sx={{ fontSize: 96 }} color="secondary" />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} lg={3} container>
            <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6" color="GrayText" style={{ fontWeight: 400, paddingBottom: 12 }}>
                    Actualizados
                  </Typography>
                  <Typography variant="h4" color="GrayText" style={{ fontWeight: 400, paddingBottom: 6 }}>
                    {statics.updated}
                  </Typography>
                  <Chip
                    label={moment(new Date()).format(`DD/MM hh:mm`)}
                    size="small"
                    style={{ backgroundColor: green[50], color: green[300], fontWeight: 800 }}
                  />
                </Grid>
                <Grid item xs={6} container alignItems={"flex-end"} justifyContent={"flex-end"}>
                  <GppGoodOutlined sx={{ fontSize: 96 }} color="success" />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={6} container>
            <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
              <Grid container direction={"column"} justifyContent={"space-between"}>
                <Grid item>
                  <Typography variant="h6" color="GrayText" style={{ fontWeight: 400 }}>
                    Resumen de contenidos
                  </Typography>
                </Grid>

                <Grid item container style={{ height: "100%", marginTop: 33 }}>
                  <Grid item xs={4} container>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{
                        height: 46,
                        width: 46,
                        backgroundColor: blue[400],
                        borderRadius: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <GppGoodOutlined style={{ color: "whitesmoke", fontSize: 32 }} />
                    </Grid>
                    <Grid style={{ marginLeft: 9 }}>
                      <Typography variant="body2" color="GrayText">
                        Usuarios registrados
                      </Typography>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {statics.users}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{
                        height: 46,
                        width: 46,
                        backgroundColor: red[400],
                        borderRadius: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorOutlined style={{ color: "whitesmoke", fontSize: 32 }} />
                    </Grid>
                    <Grid style={{ marginLeft: 9 }}>
                      <Typography variant="body2" color="GrayText">
                        errores
                      </Typography>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        0
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{
                        height: 46,
                        width: 46,
                        backgroundColor: amber[400],
                        borderRadius: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ArticleOutlined style={{ color: "whitesmoke", fontSize: 32 }} />
                    </Grid>
                    <Grid style={{ marginLeft: 9 }}>
                      <Typography variant="body2" color="GrayText">
                        Acciones de usuarios
                      </Typography>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {statics.logs}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item lg={7.5} container>
            <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={6.5}>
                  <Typography variant="h5" color={"GrayText"} style={{ fontWeight: 400 }}>
                    Últimos días de actualización
                  </Typography>
                  <Bar
                    options={{
                      responsive: true,

                      plugins: {
                        legend: { display: false },
                      },
                      scales: {
                        x: {
                          stacked: true,
                          //display: false,
                        },
                        y: {
                          stacked: true,
                        },
                      },
                    }}
                    data={{
                      labels: ["12/05", "13/05", "14/05", "15/05", "16/05"],
                      datasets: [
                        {
                          label: "",
                          data: [
                            [0, 5],
                            [0, 5],
                            [0, 5],
                            [0, 5],
                            [0, 5],
                            [0, 5],
                            [0, 5],
                          ],
                          backgroundColor: lightGreen[500],
                          borderRadius: 50,
                          borderSkipped: "false",
                          barThickness: 10,
                          categoryPercentage: 0.7,
                        },
                        {
                          label: "",
                          data: [
                            [1, 7],
                            [0, 7],
                            [0, 7],
                            [0, 7],
                            [0, 7],
                            [0, 7],
                            [0, 7],
                          ],
                          backgroundColor: blue[500],
                          borderRadius: 50,
                          borderSkipped: "false",
                          barThickness: 10,
                        },
                        {
                          label: "",
                          data: [
                            [0, 3],
                            [0, 9],
                            [0, 9],
                            [0, 9],
                            [0, 9],
                            [0, 9],
                            [0, 9],
                          ],
                          backgroundColor: purple[500],
                          borderRadius: 50,
                          borderSkipped: false,
                          barThickness: 10,
                        },
                      ],
                    }}
                  />
                </Grid>
                <Grid item xs={0.5} container justifyContent={"center"}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5} container>
                  <Typography variant="h5" color={"GrayText"} style={{ fontWeight: 400 }}>
                    Últimas actualizaciones
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {statics.lastLogs.map((l) => (
                      <ListItem alignItems="flex-start" key={l.id} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <ListItemAvatar>
                          <Avatar>{l.email.charAt(0).toUpperCase()}</Avatar>
                        </ListItemAvatar>
                        <Tooltip title={l.description}>
                          <ListItemText
                            primary={`${l.email}`}
                            secondary={
                              <React.Fragment>
                                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                                  {`${moment(l.createdAt).format("HH:mm:ss")} · `}
                                </Typography>
                                {l.serial}
                              </React.Fragment>
                            }
                          />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={4.5} container spacing={2}>
            <Grid item lg={12} container>
              <Card style={{ padding: 16, borderRadius: 6, width: "100%", height: 170 }} elevation={4}>
                <Typography variant="h6" color={"GrayText"} gutterBottom style={{ fontWeight: 400 }}>
                  ¿Está el equipo actualizado?
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Número de serie"
                  variant="outlined"
                  value={serialToFound}
                  type={"number"}
                  onChange={onChange}
                  fullWidth
                  placeholder="20045566"
                />
                <Grid container justifyContent="center">
                  <Typography variant="h4" color={isSerialUpdated ? "green" : "red"} style={{ fontWeight: 900, marginTop: 18 }}>
                    {isLoading ? <CircularProgress /> : isSerialUpdated !== null ? isSerialUpdated ? "SI" : "No" : "--"}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={6} container>
              <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
                <Grid container justifyContent={"center"}>
                  <Grid container alignItems={"center"}>
                    <Typography variant="h5" color={"GrayText"} style={{ fontWeight: 500, alignItems: "center" }}>
                      {((statics.updated / statics.totalDevices) * 100).toFixed(2)} %
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Doughnut
                      options={{
                        cutout: 30,
                        legend: {
                          display: false,
                        },
                      }}
                      data={{
                        labels: [],
                        datasets: [
                          {
                            label: "45%",
                            data: [
                              ((statics.updated / statics.totalDevices) * 100).toFixed(2),
                              ((1 - statics.updated / statics.totalDevices) * 100).toFixed(2),
                            ],
                            backgroundColor: ["#64dd17", "#cfd8dc"],
                            borderColor: [grey[500]],
                            borderWidth: 0,
                            circumference: 180,
                            rotation: -90,
                            innerRadius: 10,
                          },
                        ],
                      }}
                    />
                  </Grid>
                  <Typography variant="body1" color={"GrayText"} style={{ fontWeight: 500 }}>
                    {"Equipos actualizados"}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={6} container>
              <Card style={{ padding: 16, borderRadius: 6, width: "100%" }} elevation={4}>
                <Grid container alignItems={"center"}>
                  <PersonOutline style={{ fontSize: 28, color: "GrayText" }} />
                  <Typography variant="h5" color={"GrayText"} style={{ fontWeight: 500, alignItems: "center" }}>
                    {statics.users}
                  </Typography>
                </Grid>
                <Grid container alignItems={"center"} justifyContent={"center"} style={{}}>
                  <Line
                    options={{
                      responsive: true,
                      animation: {
                        duration: 750,
                        easing: "easeOutCubic",
                        delay: 0,
                        loop: false,
                      },

                      scales: {
                        xAxis: {
                          display: false,
                        },
                        yAxis: {
                          display: false,
                        },
                      },
                      plugins: {
                        legend: { display: false },
                      },
                    }}
                    data={{
                      datasets: [
                        {
                          data: statics.usersAgg,
                          cubicInterpolationMode: "monotone",
                          pointRadius: 0,
                          fill: true,
                          borderColor: purple[300],
                          tension: 0.1,
                          borderWidth: 7,
                          borderCapStyle: "round",
                        },
                      ],
                    }}
                  />

                  <Typography variant="body1" color={"GrayText"} style={{ fontWeight: 500 }}>
                    {"Evolución de usuarios"}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
const condition = (authUser) => authUser.email == "bdiez@bexencardio.com" || authUser.email == "lmayor@bexencardio.com";
export default withAuthorization(condition)(Dashboard);
