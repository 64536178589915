import "./App.css";
import "./i18n";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import * as ROUTES from "./constants/Routes";
import LandingPage_V2 from "./pages/LandingPage_V2";
import SignIn from "./pages/SignIn";
import withAuthentication from "./components/Session/withAuthentication";
import Home from "./pages/Home";
import UpdateProccess from "./pages/UpdateProccess";
import SeriaLNumberSteps from "./pages/SeriaLNumberSteps";
import SoftwareVersionSteps from "./pages/SoftwareVersionSteps";
import VerificationPage from "./pages/VerificationPage";
import RecoverPassword from "./pages/RecoverPassword";
import Dashboard from "./pages/Dashboard";
import Policy from "./pages/Policy";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route exact path={ROUTES.LANDING_PAGE} element={<LandingPage_V2 />} />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.UPDATE} element={<UpdateProccess />} />
        <Route path={ROUTES.SERIALNUMBER} element={<SeriaLNumberSteps />} />
        <Route path={ROUTES.SOFWAREVERSION} element={<SoftwareVersionSteps />} />
        <Route path={ROUTES.VERIFY} element={<VerificationPage />} />
        <Route path={ROUTES.RECOVERPASSWORD} element={<RecoverPassword />} />
        <Route path={ROUTES.CONTINUEUPDATE} element={<UpdateProccess />} />
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.POLICY} element={<Policy />} />
        <Route path="*" element={<Navigate to={ROUTES.LANDING_PAGE} replace />} />
      </Routes>
    </Router>
  );
}

export default withAuthentication(App);
