import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import LangSelect from "./LangSelect";

const SerialNumberCheck = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [serialNumber, setSerialNumber] = useState("");
  const [iserror, setIserror] = useState(false);
  const [helperText, sethelperText] = useState("");
  const [openingText, setOpeningText] = useState("");
  const [oldSerial, setOldSerial] = useState(false);
  const [open, setopen] = useState(false);
  const [isAffected, setIsAffected] = useState(false);

  const onChange = (e) => {
    setSerialNumber(e.target.value.trim().slice(0, 8));
    //iserror && checkSN();
    //e.target.value.trim().length == 8 && checkSN();
  };

  useEffect(() => {
    //  iserror && checkSN();
    serialNumber.length == 8 && checkSN();
    if (serialNumber.length !== 8) {
      sethelperText("");
      setIserror(false);
      setOldSerial(false);
    }
  }, [serialNumber]);

  const checkSN = () => {
    let sn = serialNumber;
    sn = sn.charAt(4) === "/" ? sn.split("/")[1] : sn;
    const regx500 = "^2005[0-9]{4,4}$";
    const regx500Old = "^9004[0-9]{4,4}$";
    const regx300 = "^2004[0-9]{4,4}$";

    //console.log(sn, new RegExp(regx500).test(serialNumber), new RegExp(regx300).test(serialNumber));

    if (serialNumber == "") {
      setIserror(false);
      sethelperText("");
      setOldSerial(false);
    } else {
      if (!new RegExp(regx500).test(sn) && !new RegExp(regx300).test(sn) && !new RegExp(regx500Old).test(sn)) {
        setIserror(true);
        sethelperText(t("invalidSerial"));
      } else if (new RegExp(regx500Old).test(sn)) {
        setOldSerial(true);
      } else {
        setIserror(false);
        sethelperText("");
        setOldSerial(false);
      }
    }
  };

  const checkIsAffected = () => {
    setIsAffected(ALLSERIALS.includes(serialNumber));
    setopen(true);
  };

  const modalClick = () => {
    setopen(false);
    isAffected && navigation(ROUTES.LANDING_PAGE);
  };
  return (
    <Grid item xs={12} container direction={"column"}>
      <Card style={{ padding: 16, borderRadius: 16, marginTop: 16 }}>
        <Grid container>
          <Grid item xs={12} container alignItems={"center"} justifyContent={"space-between"} style={{ marginBottom: 9 }}>
            <Typography variant="h5" style={{ fontWeight: 600, color: lightBlue[500] }}>
              {t("checkIfDeviceIsAffected")}
            </Typography>
            <LangSelect />
          </Grid>

          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} md={8} lg={10} container alignItems={"center"}>
              <TextField
                id="outlined-basic"
                label={t("enterSerial")}
                variant="outlined"
                fullWidth
                error={iserror}
                onChange={onChange}
                helperText={helperText}
                value={serialNumber}
                onBlur={checkSN}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Button
                variant="outlined"
                fullWidth
                disabled={iserror || serialNumber == ""}
                onClick={checkIsAffected}
                style={{ height: "100%" }}
              >
                {t("check")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Dialog open={open}>
        <DialogTitle>{isAffected ? t("deviceAffected") : t("deviceNoAffected")}</DialogTitle>
        <DialogContent>{isAffected ? t("dialogContentAffected") : t("dialogContentNoAffected")}</DialogContent>
        <DialogActions>
          <Button onClick={modalClick}>{isAffected ? t("startUpdateProccess") : t("finish")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SerialNumberCheck;

const ALLSERIALS = [
  "20040502",
  "20040503",
  "20040505",
  "20040508",
  "20040510",
  "20040513",
  "20040514",
  "20040515",
  "20040516",
  "20040517",
  "20040518",
  "20040519",
  "20040520",
  "20040521",
  "20040522",
  "20040523",
  "20040525",
  "20040526",
  "20040527",
  "20040529",
  "20040530",
  "20040535",
  "20040536",
  "20040546",
  "20040550",
  "20040557",
  "20040558",
  "20040576",
  "20040579",
  "20040586",
  "20040587",
  "20040591",
  "20040592",
  "20040593",
  "20040594",
  "20040595",
  "20040597",
  "20040598",
  "20040599",
  "20040600",
  "20040601",
  "20040605",
  "20040612",
  "20040617",
  "20040618",
  "20040639",
  "20040646",
  "20040649",
  "20040651",
  "20040653",
  "20040654",
  "20040655",
  "20040657",
  "20040658",
  "20040659",
  "20040660",
  "20040661",
  "20040663",
  "20040667",
  "20040668",
  "20040669",
  "20040670",
  "20040674",
  "20040675",
  "20040677",
  "20040681",
  "20040683",
  "20040691",
  "20040694",
  "20040695",
  "20040696",
  "20040700",
  "20040706",
  "20040707",
  "20040708",
  "20040709",
  "20040712",
  "20040713",
  "20040715",
  "20040717",
  "20040719",
  "20040721",
  "20040730",
  "20040731",
  "20040733",
  "20040742",
  "20040743",
  "20040753",
  "20040755",
  "20040756",
  "20040761",
  "20040763",
  "20040764",
  "20040767",
  "20040768",
  "20040770",
  "20040771",
  "20040778",
  "20040781",
  "20040783",
  "20040784",
  "20040785",
  "20040786",
  "20040788",
  "20040795",
  "20040797",
  "20040798",
  "20040799",
  "20040807",
  "20040813",
  "20040819",
  "20040827",
  "20040828",
  "20040834",
  "20040836",
  "20040837",
  "20040839",
  "20040840",
  "20040849",
  "20040852",
  "20040855",
  "20040856",
  "20040858",
  "20040861",
  "20040862",
  "20040864",
  "20040865",
  "20040866",
  "20040867",
  "20040870",
  "20040871",
  "20040873",
  "20040874",
  "20040878",
  "20040880",
  "20040885",
  "20040889",
  "20040890",
  "20040898",
  "20040899",
  "20040918",
  "20040919",
  "20040924",
  "20040925",
  "20040926",
  "20040927",
  "20040970",
  "20040972",
  "20040975",
  "20040976",
  "20040977",
  "20040980",
  "20040981",
  "20040984",
  "20040985",
  "20040989",
  "20040992",
  "20040993",
  "20040994",
  "20040995",
  "20040998",
  "20041003",
  "20041004",
  "20041005",
  "20041006",
  "20041019",
  "20041027",
  "20041030",
  "20041032",
  "20041035",
  "20041037",
  "20041038",
  "20041043",
  "20041044",
  "20041051",
  "20041053",
  "20041054",
  "20041057",
  "20041061",
  "20041063",
  "20041064",
  "20041065",
  "20041067",
  "20041068",
  "20041069",
  "20041070",
  "20041078",
  "20041080",
  "20041093",
  "20041109",
  "20041110",
  "20041125",
  "20041171",
  "20041180",
  "20041189",
  "20041193",
  "20041194",
  "20041199",
  "20041200",
  "20041209",
  "20041220",
  "20041222",
  "20041233",
  "20041243",
  "20041246",
  "20041255",
  "20041256",
  "20041259",
  "20041260",
  "20041266",
  "20041267",
  "20041268",
  "20041273",
  "20041274",
  "20041275",
  "20041281",
  "20041282",
  "20041284",
  "20041308",
  "20041311",
  "20041316",
  "20041317",
  "20041339",
  "20041346",
  "20041349",
  "20041352",
  "20041368",
  "20041369",
  "20041377",
  "20041378",
  "20041379",
  "20041380",
  "20041401",
  "20041407",
  "20041410",
  "20041412",
  "20041427",
  "20041446",
  "20041458",
  "20041459",
  "20041468",
  "20041480",
  "20041483",
  "20041484",
  "20041489",
  "20041490",
  "20041491",
  "20041492",
  "20041493",
  "20041494",
  "20041495",
  "20041496",
  "20041497",
  "20041498",
  "20041499",
  "20041500",
  "20041501",
  "20041502",
  "20041503",
  "20041504",
  "20041505",
  "20041506",
  "20041507",
  "20041508",
  "20041509",
  "20041510",
  "20041511",
  "20041512",
  "20041513",
  "20041514",
  "20041515",
  "20041516",
  "20041517",
  "20041518",
  "20041519",
  "20041520",
  "20041521",
  "20041522",
  "20041523",
  "20041524",
  "20041525",
  "20041526",
  "20041527",
  "20041528",
  "20041529",
  "20041530",
  "20041531",
  "20041532",
  "20041533",
  "20041534",
  "20041535",
  "20041536",
  "20041537",
  "20041538",
  "20041539",
  "20041540",
  "20041541",
  "20041542",
  "20041543",
  "20041544",
  "20041545",
  "20041546",
  "20041547",
  "20041548",
  "20041549",
  "20041550",
  "20041551",
  "20041552",
  "20041553",
  "20041554",
  "20041555",
  "20041556",
  "20041557",
  "20041558",
  "20041559",
  "20041560",
  "20041561",
  "20041562",
  "20041563",
  "20041564",
  "20041565",
  "20041566",
  "20041567",
  "20041568",
  "20041569",
  "20041570",
  "20041571",
  "20041572",
  "20041573",
  "20041574",
  "20041575",
  "20041576",
  "20041577",
  "20041578",
  "20041579",
  "20041580",
  "20041581",
  "20041582",
  "20041583",
  "20041584",
  "20041585",
  "20041586",
  "20041587",
  "20041588",
  "20041589",
  "20041590",
  "20041591",
  "20041592",
  "20041593",
  "20041594",
  "20041595",
  "20041596",
  "20041597",
  "20041598",
  "20041599",
  "20041600",
  "20041601",
  "20041602",
  "20041603",
  "20041604",
  "20041605",
  "20041606",
  "20041607",
  "20041608",
  "20041609",
  "20041610",
  "20041611",
  "20041612",
  "20041613",
  "20041614",
  "20041615",
  "20041616",
  "20041617",
  "20041618",
  "20041619",
  "20041620",
  "20041621",
  "20041622",
  "20041623",
  "20041624",
  "20041625",
  "20041626",
  "20041627",
  "20041628",
  "20041629",
  "20041630",
  "20041631",
  "20041632",
  "20041633",
  "20041634",
  "20041635",
  "20041636",
  "20041637",
  "20041638",
  "20041639",
  "20041640",
  "20041641",
  "20041642",
  "20041643",
  "20041644",
  "20041645",
  "20041646",
  "20041647",
  "20041648",
  "20041649",
  "20041650",
  "20041651",
  "20041652",
  "20041653",
  "20041654",
  "20041655",
  "20041656",
  "20041657",
  "20041658",
  "20041659",
  "20041660",
  "20041661",
  "20041662",
  "20041663",
  "20041664",
  "20041665",
  "20041666",
  "20041667",
  "20041668",
  "20041669",
  "20041670",
  "20041671",
  "20041672",
  "20041673",
  "20041674",
  "20041675",
  "20041676",
  "20041677",
  "20041678",
  "20041679",
  "20041680",
  "20041681",
  "20041682",
  "20041683",
  "20041684",
  "20041685",
  "20041686",
  "20041687",
  "20041688",
  "20041689",
  "20041690",
  "20041691",
  "20041692",
  "20041693",
  "20041694",
  "20041695",
  "20041696",
  "20041697",
  "20041698",
  "20041699",
  "20041700",
  "20041701",
  "20041702",
  "20041703",
  "20041704",
  "20041705",
  "20041706",
  "20041707",
  "20041708",
  "20041709",
  "20041710",
  "20041711",
  "20041712",
  "20041713",
  "20041714",
  "20041715",
  "20041716",
  "20041717",
  "20041718",
  "20041719",
  "20041720",
  "20041721",
  "20041722",
  "20041723",
  "20041724",
  "20041725",
  "20041726",
  "20041727",
  "20041728",
  "20041729",
  "20041730",
  "20041731",
  "20041732",
  "20041733",
  "20041734",
  "20041735",
  "20041736",
  "20041737",
  "20041738",
  "20041739",
  "20041740",
  "20041741",
  "20041742",
  "20041743",
  "20041744",
  "20041745",
  "20041746",
  "20041747",
  "20041748",
  "20041749",
  "20041750",
  "20041751",
  "20041752",
  "20041753",
  "20041754",
  "20041755",
  "20041756",
  "20041757",
  "20041758",
  "20041759",
  "20041760",
  "20041761",
  "20041762",
  "20041763",
  "20041764",
  "20041765",
  "20041766",
  "20041767",
  "20041768",
  "20041769",
  "20041770",
  "20041771",
  "20041772",
  "20041773",
  "20041774",
  "20041775",
  "20041776",
  "20041777",
  "20041778",
  "20041779",
  "20041780",
  "20041781",
  "20041782",
  "20041783",
  "20041784",
  "20041785",
  "20041786",
  "20041787",
  "20041788",
  "20041789",
  "20041790",
  "20041791",
  "20041792",
  "20041793",
  "20041794",
  "20041795",
  "20041796",
  "20041797",
  "20041798",
  "20041799",
  "20041800",
  "20041801",
  "20041802",
  "20041803",
  "20041804",
  "20041805",
  "20041806",
  "20041807",
  "20041808",
  "20041809",
  "20041810",
  "20041811",
  "20041812",
  "20041813",
  "20041814",
  "20041815",
  "20041816",
  "20041817",
  "20041818",
  "20041819",
  "20041820",
  "20041821",
  "20041822",
  "20041823",
  "20041824",
  "20041825",
  "20041826",
  "20041827",
  "20041828",
  "20041829",
  "20041830",
  "20041831",
  "20041832",
  "20041833",
  "20041834",
  "20041835",
  "20041836",
  "20041837",
  "20041838",
  "20041839",
  "20041840",
  "20041841",
  "20041842",
  "20041843",
  "20041844",
  "20041845",
  "20041846",
  "20041847",
  "20041848",
  "20041849",
  "20041850",
  "20041851",
  "20041852",
  "20041853",
  "20041854",
  "20041855",
  "20041857",
  "20041858",
  "20041859",
  "20041860",
  "20041861",
  "20041862",
  "20041863",
  "20041864",
  "20041865",
  "20041866",
  "20041867",
  "20041868",
  "20041869",
  "20041870",
  "20041871",
  "20041872",
  "20041873",
  "20041874",
  "20041875",
  "20041876",
  "20041877",
  "20041878",
  "20041879",
  "20041880",
  "20041881",
  "20041882",
  "20041883",
  "20041884",
  "20041885",
  "20041886",
  "20041887",
  "20041888",
  "20041889",
  "20041890",
  "20041891",
  "20041892",
  "20041893",
  "20041894",
  "20041895",
  "20041896",
  "20041897",
  "20041898",
  "20041899",
  "20041900",
  "20041901",
  "20041902",
  "20041903",
  "20041904",
  "20041905",
  "20041906",
  "20041907",
  "20041908",
  "20041909",
  "20041910",
  "20041911",
  "20041912",
  "20041913",
  "20041914",
  "20041915",
  "20041916",
  "20041917",
  "20041919",
  "20041920",
  "20041921",
  "20041922",
  "20041923",
  "20041924",
  "20041925",
  "20041926",
  "20041927",
  "20041928",
  "20041929",
  "20041930",
  "20041931",
  "20041932",
  "20041933",
  "20041934",
  "20041935",
  "20041936",
  "20041937",
  "20041938",
  "20041939",
  "20041940",
  "20041941",
  "20041942",
  "20041943",
  "20041944",
  "20041945",
  "20041946",
  "20041947",
  "20041948",
  "20041949",
  "20041950",
  "20041951",
  "20041952",
  "20041953",
  "20041954",
  "20041955",
  "20041956",
  "20041957",
  "20041958",
  "20041959",
  "20041960",
  "20041961",
  "20041962",
  "20041963",
  "20041964",
  "20041965",
  "20041966",
  "20041967",
  "20041968",
  "20041969",
  "20041970",
  "20041971",
  "20041972",
  "20041973",
  "20041974",
  "20041975",
  "20041976",
  "20041977",
  "20041978",
  "20041979",
  "20041980",
  "20041981",
  "20041982",
  "20041983",
  "20041984",
  "20041985",
  "20041986",
  "20041987",
  "20041988",
  "20041989",
  "20041990",
  "20041991",
  "20041992",
  "20041993",
  "20041994",
  "20041995",
  "20041996",
  "20041997",
  "20041998",
  "20041999",
  "20042000",
  "20042001",
  "20042002",
  "20042003",
  "20042004",
  "20042005",
  "20042006",
  "20042007",
  "20042008",
  "20042009",
  "20042010",
  "20042011",
  "20042012",
  "20042013",
  "20042014",
  "20042015",
  "20042016",
  "20042017",
  "20042018",
  "20042019",
  "20042020",
  "20042021",
  "20042022",
  "20042023",
  "20042024",
  "20042025",
  "20042026",
  "20042027",
  "20042028",
  "20042029",
  "20042030",
  "20042031",
  "20042032",
  "20042033",
  "20042034",
  "20042035",
  "20042036",
  "20042037",
  "20042038",
  "20042039",
  "20042040",
  "20042041",
  "20042042",
  "20042043",
  "20042044",
  "20042045",
  "20042046",
  "20042047",
  "20042048",
  "20042049",
  "20042050",
  "20042051",
  "20042052",
  "20042053",
  "20042054",
  "20042055",
  "20042056",
  "20042057",
  "20042058",
  "20042059",
  "20042060",
  "20042061",
  "20042062",
  "20042063",
  "20042064",
  "20042065",
  "20042066",
  "20042067",
  "20042068",
  "20042069",
  "20042070",
  "20042071",
  "20042072",
  "20042073",
  "20042074",
  "20042075",
  "20042076",
  "20042077",
  "20042078",
  "20042079",
  "20042080",
  "20042081",
  "20042082",
  "20042084",
  "20042085",
  "20042086",
  "20042087",
  "20042088",
  "20042089",
  "20042090",
  "20042091",
  "20042092",
  "20042093",
  "20042094",
  "20042095",
  "20042096",
  "20042097",
  "20042098",
  "20042099",
  "20042100",
  "20042101",
  "20042102",
  "20042103",
  "20042104",
  "20042105",
  "20042106",
  "20042108",
  "20042109",
  "20042110",
  "20042111",
  "20042112",
  "20042113",
  "20042114",
  "20042115",
  "20042116",
  "20042117",
  "20042118",
  "20042119",
  "20042120",
  "20042121",
  "20042122",
  "20042123",
  "20042124",
  "20042125",
  "20042126",
  "20042127",
  "20042128",
  "20042129",
  "20042130",
  "20042131",
  "20042132",
  "20042133",
  "20042134",
  "20042135",
  "20042136",
  "20042137",
  "20042138",
  "20042139",
  "20042140",
  "20042141",
  "20042142",
  "20042143",
  "20042144",
  "20042145",
  "20042146",
  "20042147",
  "20042148",
  "20042149",
  "20042150",
  "20042151",
  "20042152",
  "20042153",
  "20042154",
  "20042155",
  "20042156",
  "20042157",
  "20042158",
  "20042159",
  "20042160",
  "20042161",
  "20042162",
  "20042163",
  "20042164",
  "20042165",
  "20042166",
  "20042167",
  "20042168",
  "20042169",
  "20042170",
  "20042171",
  "20042172",
  "20042173",
  "20042174",
  "20042175",
  "20042176",
  "20042177",
  "20042178",
  "20042179",
  "20042180",
  "20042181",
  "20042182",
  "20042183",
  "20042184",
  "20042185",
  "20042186",
  "20042187",
  "20042188",
  "20042189",
  "20042190",
  "20042191",
  "20042192",
  "20042193",
  "20042194",
  "20042195",
  "20042196",
  "20042197",
  "20042198",
  "20042199",
  "20042200",
  "20042201",
  "20042202",
  "20042203",
  "20042204",
  "20042205",
  "20042206",
  "20042207",
  "20042208",
  "20042209",
  "20042210",
  "20042211",
  "20042212",
  "20042213",
  "20042214",
  "20042215",
  "20042216",
  "20042217",
  "20042218",
  "20042219",
  "20042220",
  "20042221",
  "20042222",
  "20042223",
  "20042224",
  "20042225",
  "20042226",
  "20042227",
  "20042228",
  "20042229",
  "20042230",
  "20042231",
  "20042232",
  "20042233",
  "20042234",
  "20042235",
  "20042236",
  "20042237",
  "20042238",
  "20042239",
  "20042240",
  "20042241",
  "20042242",
  "20042243",
  "20042244",
  "20042245",
  "20042246",
  "20042247",
  "20042248",
  "20042249",
  "20042250",
  "20042251",
  "20042252",
  "20042253",
  "20042254",
  "20042255",
  "20042256",
  "20042257",
  "20042258",
  "20042259",
  "20042260",
  "20042261",
  "20042262",
  "20042263",
  "20042264",
  "20042265",
  "20042266",
  "20042267",
  "20042268",
  "20042269",
  "20042270",
  "20042271",
  "20042272",
  "20042273",
  "20042274",
  "20042275",
  "20042276",
  "20042277",
  "20042278",
  "20042279",
  "20042280",
  "20042281",
  "20042282",
  "20042283",
  "20042284",
  "20042285",
  "20042286",
  "20042287",
  "20042288",
  "20042289",
  "20042290",
  "20042291",
  "20042292",
  "20042293",
  "20042294",
  "20042295",
  "20042296",
  "20042297",
  "20042298",
  "20042299",
  "20042300",
  "20042301",
  "20042302",
  "20042303",
  "20042304",
  "20042305",
  "20042306",
  "20042307",
  "20042308",
  "20042309",
  "20042310",
  "20042311",
  "20042312",
  "20042313",
  "20042314",
  "20042315",
  "20042316",
  "20042317",
  "20042318",
  "20042319",
  "20042320",
  "20042321",
  "20042322",
  "20042323",
  "20042324",
  "20042325",
  "20042326",
  "20042327",
  "20042328",
  "20042329",
  "20042330",
  "20042331",
  "20042332",
  "20042333",
  "20042334",
  "20042335",
  "20042336",
  "20042337",
  "20042338",
  "20042339",
  "20042340",
  "20042341",
  "20042342",
  "20042343",
  "20042344",
  "20042345",
  "20042346",
  "20042347",
  "20042348",
  "20042349",
  "20042350",
  "20042351",
  "20042352",
  "20042353",
  "20042354",
  "20042355",
  "20042356",
  "20042357",
  "20042358",
  "20042359",
  "20042360",
  "20042361",
  "20042362",
  "20042363",
  "20042364",
  "20042365",
  "20042366",
  "20042367",
  "20042368",
  "20042369",
  "20042370",
  "20042371",
  "20042372",
  "20042373",
  "20042374",
  "20042375",
  "20042376",
  "20042377",
  "20042378",
  "20042379",
  "20042380",
  "20042381",
  "20042382",
  "20042383",
  "20042384",
  "20042385",
  "20042386",
  "20042387",
  "20042388",
  "20042389",
  "20042390",
  "20042391",
  "20042392",
  "20042393",
  "20042394",
  "20042395",
  "20042396",
  "20042397",
  "20042398",
  "20042399",
  "20042400",
  "20042401",
  "20042402",
  "20042403",
  "20042404",
  "20042405",
  "20042406",
  "20042407",
  "20042408",
  "20042409",
  "20042410",
  "20042411",
  "20042412",
  "20042413",
  "20042414",
  "20042415",
  "20042416",
  "20042417",
  "20042418",
  "20042419",
  "20042420",
  "20042421",
  "20042422",
  "20042423",
  "20042424",
  "20042425",
  "20042426",
  "20042427",
  "20042428",
  "20042429",
  "20042430",
  "20042431",
  "20042432",
  "20042433",
  "20042434",
  "20042435",
  "20042436",
  "20042437",
  "20042438",
  "20042439",
  "20042440",
  "20042441",
  "20042442",
  "20042443",
  "20042444",
  "20042445",
  "20042446",
  "20042447",
  "20042448",
  "20042449",
  "20042450",
  "20042451",
  "20042452",
  "20042453",
  "20042454",
  "20042455",
  "20042456",
  "20042457",
  "20042458",
  "20042459",
  "20042460",
  "20042461",
  "20042462",
  "20042463",
  "20042464",
  "20042465",
  "20042466",
  "20042467",
  "20042468",
  "20042469",
  "20042470",
  "20042471",
  "20042472",
  "20042473",
  "20042474",
  "20042475",
  "20042476",
  "20042477",
  "20042478",
  "20042479",
  "20042480",
  "20042481",
  "20042482",
  "20042483",
  "20042484",
  "20042485",
  "20042486",
  "20042487",
  "20042488",
  "20042489",
  "20042490",
  "20042491",
  "20042492",
  "20042493",
  "20042494",
  "20042495",
  "20042496",
  "20042497",
  "20042498",
  "20042499",
  "20042500",
  "20042501",
  "20042502",
  "20042503",
  "20042504",
  "20042505",
  "20042506",
  "20042507",
  "20042508",
  "20042509",
  "20042510",
  "20042511",
  "20042512",
  "20042513",
  "20042514",
  "20042515",
  "20042516",
  "20042517",
  "20042518",
  "20042519",
  "20042520",
  "20042521",
  "20042522",
  "20042523",
  "20042524",
  "20042525",
  "20042526",
  "20042527",
  "20042528",
  "20042529",
  "20042530",
  "20042531",
  "20042532",
  "20042533",
  "20042534",
  "20042535",
  "20042536",
  "20042537",
  "20042538",
  "20042539",
  "20042540",
  "20042541",
  "20042542",
  "20042543",
  "20042544",
  "20042545",
  "20042546",
  "20042547",
  "20042548",
  "20042549",
  "20042550",
  "20042551",
  "20042552",
  "20042553",
  "20042554",
  "20042555",
  "20042556",
  "20042557",
  "20042558",
  "20042559",
  "20042560",
  "20042561",
  "20042562",
  "20042563",
  "20042564",
  "20042565",
  "20042566",
  "20042567",
  "20042568",
  "20042569",
  "20042570",
  "20042571",
  "20042572",
  "20042573",
  "20042574",
  "20042575",
  "20042576",
  "20042577",
  "20042578",
  "20042579",
  "20042580",
  "20042581",
  "20042582",
  "20042583",
  "20042584",
  "20042585",
  "20042586",
  "20042587",
  "20042588",
  "20042589",
  "20042590",
  "20042591",
  "20042592",
  "20042593",
  "20042594",
  "20042595",
  "20042597",
  "20042598",
  "20042599",
  "20042600",
  "20042601",
  "20042602",
  "20042603",
  "20042604",
  "20042605",
  "20042606",
  "20042607",
  "20042608",
  "20042609",
  "20042610",
  "20042611",
  "20042612",
  "20042613",
  "20042614",
  "20042615",
  "20042616",
  "20042617",
  "20042618",
  "20042619",
  "20042620",
  "20042621",
  "20042622",
  "20042623",
  "20042624",
  "20042625",
  "20042626",
  "20042627",
  "20042628",
  "20042629",
  "20042630",
  "20042631",
  "20042632",
  "20042633",
  "20042634",
  "20042635",
  "20042636",
  "20042637",
  "20042638",
  "20042639",
  "20042640",
  "20042641",
  "20042642",
  "20042643",
  "20042644",
  "20042645",
  "20042646",
  "20042647",
  "20042648",
  "20042649",
  "20042650",
  "20042651",
  "20042652",
  "20042653",
  "20042654",
  "20042655",
  "20042656",
  "20042657",
  "20042658",
  "20042659",
  "20042660",
  "20042661",
  "20042662",
  "20042663",
  "20042664",
  "20042665",
  "20042666",
  "20042667",
  "20042668",
  "20042669",
  "20042670",
  "20042671",
  "20042672",
  "20042673",
  "20042674",
  "20042675",
  "20042676",
  "20042677",
  "20042678",
  "20042679",
  "20042680",
  "20042681",
  "20042682",
  "20042683",
  "20042684",
  "20042685",
  "20042686",
  "20042687",
  "20042688",
  "20042689",
  "20042690",
  "20042691",
  "20042692",
  "20042693",
  "20042694",
  "20042695",
  "20042696",
  "20042697",
  "20042698",
  "20042699",
  "20042700",
  "20042702",
  "20042703",
  "20042704",
  "20042705",
  "20042706",
  "20042707",
  "20042708",
  "20042709",
  "20042710",
  "20042711",
  "20042712",
  "20042713",
  "20042714",
  "20042715",
  "20042716",
  "20042717",
  "20042718",
  "20042719",
  "20042720",
  "20042721",
  "20042722",
  "20042723",
  "20042724",
  "20042725",
  "20042726",
  "20042727",
  "20042728",
  "20042729",
  "20042730",
  "20042731",
  "20042732",
  "20042733",
  "20042734",
  "20042735",
  "20042736",
  "20042738",
  "20042740",
  "20042741",
  "20042742",
  "20042743",
  "20042744",
  "20042745",
  "20042746",
  "20042747",
  "20042748",
  "20042749",
  "20042750",
  "20042751",
  "20042752",
  "20042753",
  "20042754",
  "20042755",
  "20042756",
  "20042757",
  "20042758",
  "20042759",
  "20042760",
  "20042761",
  "20042762",
  "20042763",
  "20042764",
  "20042765",
  "20042766",
  "20042767",
  "20042768",
  "20042769",
  "20042770",
  "20042771",
  "20042772",
  "20042773",
  "20042774",
  "20042775",
  "20042776",
  "20042777",
  "20042778",
  "20042779",
  "20042780",
  "20042781",
  "20042782",
  "20042783",
  "20042784",
  "20042785",
  "20042786",
  "20042787",
  "20042788",
  "20042789",
  "20042790",
  "20042791",
  "20042792",
  "20042793",
  "20042794",
  "20042795",
  "20042796",
  "20042797",
  "20042798",
  "20042799",
  "20042800",
  "20042801",
  "20042802",
  "20042803",
  "20042804",
  "20042805",
  "20042806",
  "20042807",
  "20042808",
  "20042809",
  "20042810",
  "20042811",
  "20042812",
  "20042813",
  "20042814",
  "20042815",
  "20042816",
  "20042817",
  "20042818",
  "20042819",
  "20042820",
  "20042821",
  "20042822",
  "20042823",
  "20042824",
  "20042825",
  "20042826",
  "20042827",
  "20042828",
  "20042829",
  "20042830",
  "20042831",
  "20042832",
  "20042833",
  "20042834",
  "20042835",
  "20042836",
  "20042837",
  "20042838",
  "20042839",
  "20042840",
  "20042841",
  "20042842",
  "20042843",
  "20042844",
  "20042845",
  "20042846",
  "20042847",
  "20042848",
  "20042849",
  "20042850",
  "20042851",
  "20042852",
  "20042853",
  "20042854",
  "20042855",
  "20042856",
  "20042857",
  "20042858",
  "20042859",
  "20042860",
  "20042861",
  "20042862",
  "20042863",
  "20042864",
  "20042865",
  "20042866",
  "20042867",
  "20042868",
  "20042869",
  "20042870",
  "20042871",
  "20042872",
  "20042873",
  "20042874",
  "20042875",
  "20042876",
  "20042877",
  "20042878",
  "20042879",
  "20042880",
  "20042881",
  "20042882",
  "20042883",
  "20042884",
  "20042885",
  "20042886",
  "20042887",
  "20042888",
  "20042889",
  "20042890",
  "20042891",
  "20042892",
  "20042893",
  "20042894",
  "20042895",
  "20042896",
  "20042897",
  "20042898",
  "20042899",
  "20042900",
  "20042901",
  "20042902",
  "20042903",
  "20042904",
  "20042905",
  "20042906",
  "20042907",
  "20042908",
  "20042909",
  "20042910",
  "20042911",
  "20042912",
  "20042913",
  "20042914",
  "20042915",
  "20042916",
  "20042917",
  "20042918",
  "20042919",
  "20042920",
  "20042921",
  "20042922",
  "20042923",
  "20042924",
  "20042925",
  "20042926",
  "20042927",
  "20042928",
  "20042929",
  "20042930",
  "20042931",
  "20042932",
  "20042933",
  "20042934",
  "20042935",
  "20042936",
  "20042937",
  "20042938",
  "20042939",
  "20042940",
  "20042941",
  "20042942",
  "20042943",
  "20042944",
  "20042945",
  "20042946",
  "20042947",
  "20042948",
  "20042949",
  "20042950",
  "20042951",
  "20042952",
  "20042953",
  "20042955",
  "20042956",
  "20042957",
  "20042958",
  "20043001",
  "20043002",
  "20043003",
  "20043006",
  "20043007",
  "20043008",
  "20043009",
  "20043010",
  "20043011",
  "20043012",
  "20043013",
  "20043014",
  "20043015",
  "20043016",
  "20043017",
  "20043018",
  "20043019",
  "20043020",
  "20043021",
  "20043022",
  "20043023",
  "20043024",
  "20043025",
  "20043026",
  "20043027",
  "20043028",
  "20043029",
  "20043030",
  "20043031",
  "20043032",
  "20043033",
  "20043034",
  "20043035",
  "20043036",
  "20043037",
  "20043038",
  "20043039",
  "20043040",
  "20043041",
  "20043042",
  "20043043",
  "20043044",
  "20043045",
  "20043046",
  "20043047",
  "20043048",
  "20043049",
  "20043050",
  "20043051",
  "20043052",
  "20043053",
  "20043054",
  "20043055",
  "20043056",
  "20043057",
  "20043058",
  "20043059",
  "20043060",
  "20043061",
  "20043062",
  "20043063",
  "20043064",
  "20043065",
  "20043066",
  "20043067",
  "20043068",
  "20043069",
  "20043070",
  "20043071",
  "20043072",
  "20043073",
  "20043074",
  "20043075",
  "20043076",
  "20043077",
  "20043078",
  "20043079",
  "20043080",
  "20043081",
  "20043082",
  "20043083",
  "20043084",
  "20043085",
  "20050505",
  "20050507",
  "20050517",
  "20050522",
  "20050525",
  "20050526",
  "20050533",
  "20050539",
  "20050540",
  "20050541",
  "20050547",
  "20050552",
  "20050553",
  "20050559",
  "20050560",
  "20050571",
  "20050573",
  "20050576",
  "20050578",
  "20050579",
  "20050581",
  "20050614",
  "20050615",
  "20050616",
  "20050617",
  "20050618",
  "20050619",
  "20050620",
  "20050621",
  "20050622",
  "20050623",
  "20050624",
  "20050625",
  "20050640",
  "20050662",
  "20050671",
  "20050672",
  "20050673",
  "20050674",
  "20050675",
  "20050676",
  "20050677",
  "20050678",
  "20050679",
  "20050680",
  "20050684",
  "20050685",
  "20050686",
  "20050687",
  "20050688",
  "20050689",
  "20050690",
  "20050691",
  "20050692",
  "20050693",
  "20050694",
  "20050695",
  "20050696",
  "20050697",
  "20050698",
  "20050699",
  "20050700",
  "20050701",
  "20050702",
  "20050703",
  "20050704",
  "20050705",
  "20050706",
  "20050707",
  "20050708",
  "20050709",
  "20050710",
  "20050711",
  "20050712",
  "20050715",
  "20050716",
  "20050717",
  "20050718",
  "20050720",
  "20050721",
  "20050722",
  "20050724",
  "20050725",
  "20050726",
  "20050727",
  "20050728",
  "20050729",
  "20050730",
  "20050731",
  "20050732",
  "20050733",
  "20050734",
  "20050735",
  "20050736",
  "20050737",
  "20050738",
  "20050739",
  "20050740",
  "20050741",
  "20050742",
  "20050743",
  "20050744",
  "20050745",
  "20050746",
  "20050747",
  "20050748",
  "20050749",
  "20050750",
  "20050751",
  "20050752",
  "20050753",
  "20050754",
  "20050755",
  "20050756",
  "20050757",
  "20050758",
  "20050759",
  "20050760",
  "20050761",
  "20050762",
  "20050763",
  "20050764",
  "20050765",
  "20050767",
  "20050769",
  "20050770",
  "20050771",
  "20050772",
  "20050773",
  "20050774",
  "20050775",
  "20050776",
  "20050777",
  "20050778",
  "20050779",
  "20050780",
  "20050781",
  "20050782",
  "20050783",
  "20050784",
  "20050785",
  "20050786",
  "20050787",
  "20050788",
  "20050789",
  "20050790",
  "20050791",
  "20050792",
  "20050793",
  "20050794",
  "20050795",
  "20050796",
  "20050797",
  "20050798",
  "20050799",
  "20050800",
  "20050801",
  "20050802",
  "20050803",
  "20050804",
  "20050805",
  "20050806",
  "20050807",
  "20050808",
  "20050809",
  "20050810",
  "20050811",
  "20050812",
  "20050813",
  "20050814",
  "20050815",
  "20050816",
  "20050817",
  "20050818",
  "20050819",
  "20050820",
  "20050821",
  "20050822",
  "20050823",
  "20050824",
  "20050825",
  "20050826",
  "20050827",
  "20050828",
  "20050829",
  "20050830",
  "20050831",
  "20050832",
  "20050834",
  "20050835",
  "20050838",
  "20050839",
  "20050840",
  "20050842",
  "20050843",
  "20050844",
  "20050845",
  "20050846",
  "20050847",
  "20050848",
  "20050849",
  "20050851",
  "20050852",
  "20050853",
  "20050854",
  "20050855",
  "20050856",
  "20050857",
  "20050858",
  "20050859",
  "20050860",
  "20050861",
  "20050862",
  "20050864",
  "20050866",
  "20050867",
  "20050868",
  "20050869",
  "20050870",
  "20050871",
  "20050872",
  "20050873",
  "20050874",
  "20050875",
  "20050876",
  "20050877",
  "20050878",
  "20050879",
  "20050880",
  "20050881",
  "20050882",
  "20050883",
  "20050884",
  "20050885",
  "20050886",
  "20050887",
  "20050888",
  "20050889",
  "20050892",
  "20050893",
  "20050894",
  "20050895",
  "20050896",
  "20050897",
  "20050898",
  "20050899",
  "20050900",
  "20050901",
  "20050902",
  "20050903",
  "20050904",
  "20050905",
  "20050906",
  "20050907",
  "20050908",
  "20050909",
  "20050910",
  "20050911",
  "20050912",
  "20050913",
  "20050914",
  "20050915",
  "20050916",
  "20050917",
  "20050918",
  "20050919",
  "20050920",
  "20050921",
  "20050922",
  "20050923",
  "20050924",
  "20050925",
  "20050926",
  "20050930",
  "20050931",
  "20050932",
  "20050933",
  "20050934",
  "20050935",
  "20050937",
  "20050938",
  "20050939",
  "20050940",
  "20050941",
  "20050942",
  "20050944",
  "20050947",
  "20050948",
  "20050949",
  "20050950",
  "20050951",
  "20050952",
  "20050953",
  "20050954",
  "20050955",
  "20050956",
  "20050957",
  "20050958",
  "20050960",
  "20050961",
  "20050962",
  "20050963",
  "20050964",
  "20050965",
  "20050966",
  "20050972",
  "20050989",
  "20050990",
  "20050991",
  "20050992",
  "20050993",
  "20050994",
  "20050995",
  "20050996",
  "20050997",
  "20051003",
  "20051004",
  "20051005",
  "20051006",
  "20051007",
  "20051008",
  "20051009",
  "20051010",
  "20051011",
  "20051018",
  "20051019",
  "20051020",
  "20051021",
  "20051022",
  "20051023",
  "20051024",
  "20051025",
  "20051026",
  "20051038",
  "20051039",
  "20051040",
  "20051041",
  "20051042",
  "20051043",
  "20051044",
  "20051045",
  "20051046",
  "20051047",
  "20051048",
  "20051049",
  "20051050",
  "20051051",
  "20051052",
  "20051053",
  "20051054",
  "20051055",
  "20051056",
  "20051057",
  "20051058",
  "20051059",
  "20051060",
  "20051061",
  "20051062",
  "20051063",
  "20051064",
  "20051065",
  "20051066",
  "20051067",
  "20051068",
  "20051069",
  "20051070",
  "20051071",
  "20051072",
  "20051073",
  "20051074",
  "20051075",
  "20051076",
  "20051077",
  "20051078",
  "20051079",
  "20051080",
  "20051081",
  "20051082",
  "20051083",
  "20051084",
  "20051085",
  "20051086",
  "20051087",
  "90040018",
  "90040019",
  "90040020",
  "90040021",
  "90040022",
  "90040023",
  "90040024",
  "90040025",
  "90040026",
  "90040027",
  "90040028",
  "90040029",
  "90040030",
  "90040031",
  "90040032",
  "90040033",
  "90040034",
  "90040035",
  "90040036",
  "90040037",
  "90040038",
  "90040039",
  "90040040",
  "90040041",
  "90040042",
  "90040043",
  "90040044",
  "90040045",
  "90040046",
  "90040047",
  "90040048",
  "90040049",
  "90040050",
  "90040051",
  "90040052",
  "20040637",
  "20040638",
  "20040640",
  "20040801",
  "20040805",
  "20040843",
  "20040893",
  "20041036",
  "20041055",
  "20041176",
  "20041261",
  "20050548",
  "20050670",
  "20050719",
  "20041400",
  "20040588",
  "20040901",
  "20041269",
  "20041270",
  "20041402",
  "20041403",
  "20041404",
  "20041405",
  "20041406",
  "20041408",
  "20041416",
  "20050583",
  "20040633",
  "20040736",
  "20040822",
  "20040774",
  "20040673",
  "20040792",
  "20041075",
  "20040664",
  "20040714",
  "20040829",
  "20040803",
  "20040711",
  "20040732",
  "20040772",
  "20040765",
  "20040875",
  "20040793",
  "20050550",
  "20040846",
  "20040876",
  "20041079",
  "20040699",
  "20040724",
  "20040580",
  "20040722",
  "20040773",
  "20040841",
  "20050667",
  "20050642",
  "20040672",
  "20041366",
  "20040917",
  "20040539",
  "20041050",
  "20041190",
  "20040845",
  "20040775",
  "20040996",
  "20040997",
  "20040896",
  "20040531",
  "20040632",
  "20041026",
  "20041031",
  "20050723",
  "20041234",
  "20050683",
  "20040635",
  "20041007",
  "20050713",
  "20040584",
  "20040641",
  "20040583",
  "20041856",
  "20040642",
  "20040923",
  "20040540",
  "20040751",
  "20041174",
  "20041056",
  "20041052",
  "20041049",
  "20040726",
  "20041232",
  "20041304",
  "20041101",
  "20040662",
  "20041198",
  "20041102",
  "20040577",
  "20040609",
  "20041918",
  "20040620",
  "20050681",
];
