import { ExpandMore, Language } from "@mui/icons-material";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LangSelect = ({ icon }) => {
  const [anchor, setanchor] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLanguaje = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        type="text"
        onClick={handleMenu}
        color="inherit"
      >
        {icon && <Language style={{ marginRight: 6 }} />}
        {t("navigation.languaje.current")}
        <ExpandMore />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          button
          onClick={() => (onChangeLanguaje("es"), setAnchorEl(false))}
        >
          <ListItemText>{t("navigation.languaje.es")}</ListItemText>
        </MenuItem>
        <MenuItem
          button
          onClick={() => (onChangeLanguaje("en"), setAnchorEl(false))}
        >
          <ListItemText>{t("navigation.languaje.en")}</ListItemText>
        </MenuItem>
        <MenuItem
          button
          onClick={() => (onChangeLanguaje("it"), setAnchorEl(false))}
        >
          <ListItemText>{t("navigation.languaje.it")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LangSelect;
