import { Alert, Button, Card, CircularProgress, Container, Grid, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as ROUTES from "../constants/Routes";
import { useSearchParams, useNavigate } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { useTranslation } from "react-i18next";
import LangSelect from "../components/LangSelect";

function VerificationPage(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { firebase } = props;

  const [loading, setLoading] = useState(true);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [helperText, setHelperText] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [verifyEmailErrorCode, setVerifyEmailErrorCode] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [opensnack, setOpensnack] = useState(false);
  const [loadingEmailSend, setLoadingEmailSend] = useState(false);
  const [isErrorOnEmailSend, setIsErrorOnEmailSend] = useState(false);

  useEffect(() => {
    const mode = searchParams.get("mode");
    switch (mode) {
      case "verifyEmail":
        emailVerify();
        break;
      case "resetPassword":
        setLoading(false);
        break;

      default:
        break;
    }
  }, []);

  const emailVerify = () => {
    const user = firebase.currentUser();
    !!user
      ? user.emailVerified
        ? navigate(ROUTES.HOME)
        : firebase
            .verifyCode(searchParams.get("oobCode"))
            .then(() => {
              setTimeout(() => {
                return window.location.reload(false);
              }, 2500);
            })
            .catch((err) => {
              console.log("error en verificacion", err);
              setVerifyEmailErrorCode(err.code);
              setLoading(false);
              setIsUser(true);
            })
      : firebase
          .verifyCode(searchParams.get("oobCode"))
          .then(() => {
            navigate(ROUTES.SIGN_IN);
          })
          .catch((err) => {
            console.log("error en verificacion", err);
            setVerifyEmailErrorCode(err.code);
            setLoading(false);
            setIsUser(false);
          });
  };

  const resetPassword = () => {
    setProcessing(true);
    let error = "";
    if (pass2 !== pass1) {
      error = "passwordNoMatch";
    } else if (pass1.length < 6) {
      error = "lengthlessthan6";
    }

    if (error == "") {
      firebase
        .verifyPasswordAndReset(searchParams.get("oobCode"), pass1)
        .then(() => {
          setIsPasswordChanged(true);
        })
        .catch((err) => {
          console.log("error", err);
          setHelperText(err.code);
          setProcessing(false);
        });
    } else {
      setProcessing(false);
      setHelperText(error);
    }
  };

  const sendVerificationEmail = () => {
    setLoadingEmailSend(true);
    firebase
      .sendEmailToVerificateUser()
      .then(() => {
        setLoadingEmailSend(true);
        setOpensnack(true);
        setIsErrorOnEmailSend(false);
      })
      .catch((err) => {
        setLoadingEmailSend(true);
        setOpensnack(true);
        setIsErrorOnEmailSend(true);
      });
  };

  if (loading) {
    return (
      <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "100vh" }}>
        <CircularProgress />
      </Grid>
    );
  } else {
    if (searchParams.get("mode") == "resetPassword") {
      if (!isPasswordChanged) {
        const isValid = pass2 != "" && pass1 != "" && helperText == "" && !processing;
        return (
          <Container>
            <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "100vh" }}>
              <Grid item xs={12} md={5} lg={4.5}>
                <Card style={{ padding: 16, borderRadius: 16 }}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} container direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="h6" color={"primary"}>
                        {t("passwordReset")} *
                      </Typography>
                      <LangSelect />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="psw1"
                        type={"password"}
                        label={t("password")}
                        value={pass1}
                        helperText={t(helperText)}
                        error={helperText !== ""}
                        onChange={(e) => {
                          setPass1(e.target.value);
                          setHelperText("");
                        }}
                        required
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="psw2"
                        type={"password"}
                        label={t("repeatPassword")}
                        value={pass2}
                        helperText={t(helperText)}
                        error={helperText !== ""}
                        onChange={(e) => {
                          setPass2(e.target.value);
                          setHelperText("");
                        }}
                        required
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" type="submit" disabled={!isValid} fullWidth onClick={resetPassword}>
                        {t("changePassword")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          navigate(ROUTES.HOME);
                        }}
                      >
                        {t("home")}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
                <Typography variant="body2" style={{ margin: 9, textAlign: "center" }}>
                  (*) {t("supportText")} support@bexencardio.com
                </Typography>
              </Grid>
            </Grid>
          </Container>
        );
      } else {
        return (
          <Grid container alignItems={"center"} style={{ height: "100vh" }} alignContent={"space-around"}>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={12} container justifyContent={"center"}>
                <Typography variant="h4" color={"GrayText"} style={{ fontWeight: 700 }}>
                  {t("passwordSuccessChanged")}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent={"center"}>
                <Button onClick={() => navigate(ROUTES.SIGN_IN)}>{t("signIn")}</Button>
                <Button onClick={() => navigate(ROUTES.LANDING_PAGE)}>{t("home")}</Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    } else if (searchParams.get("mode") == "verifyEmail") {
      return (
        <Grid container justifyContent={"center"} style={{ height: "100vh" }} alignContent={"space-around"}>
          <Grid item xs={12} md={10} lg={5}>
            <Card style={{ padding: 16, borderRadius: 16 }}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color={"error"} style={{ fontWeight: 400 }}>
                    :( Error de verificación
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{t(!isUser ? "errorEmailVerificationNoUser" : verifyEmailErrorCode)}</Typography>
                </Grid>

                <Grid item xs={12} container justifyContent={"right"}>
                  {!isUser ? (
                    <Button variant="text" onClick={() => navigate(ROUTES.SIGN_IN)} style={{ marginLeft: 9, textTransform: "none" }}>
                      Inicio
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled={!isUser}
                      onClick={sendVerificationEmail}
                      style={{ marginLeft: 9, textTransform: "none" }}
                    >
                      Solicitar nueva verificación
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Snackbar
            open={opensnack}
            autoHideDuration={6000}
            onClose={() => setOpensnack(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert onClose={() => setOpensnack(false)} severity={isErrorOnEmailSend ? "error" : "success"} sx={{ width: "100%" }}>
              {isErrorOnEmailSend
                ? t("errorOnSendVerificationEmail")
                : t("successOnSendVerificationEmail", { email: !!isUser && firebase.currentUser().email })}
            </Alert>
          </Snackbar>
        </Grid>
      );
    }
  }
}

export default withFirebase(VerificationPage);
